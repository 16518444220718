/* tslint:disable */
// TOTVS TypeScript translaton resource
// Reference date : 20250227

export const literalsPtBR = {
  str0001: "Módulo de Compras",
  str0002: "Necessidade de Compra",
  str0003: "Início",
  str0004: "Cotações",
  str0005: "Pedido de compra",
  str0006: "Histórico de compras",
  str0007: "Indicadores",
  str0008: "Em aberto",
  str0009: "Parcialmente atendidas",
  str0010: "Atendidas",
  str0011: "Selecione o período",
  str0012: "Busque por código de produto",
  str0013: "Busca avançada",
  str0014: "Sair",
  str0015: "Solicitação",
  str0016: "Item solicitação",
  str0017: "Código do produto",
  str0018: "Descrição do produto",
  str0019: "Data necessidade",
  str0020: "Unidade de medida",
  str0021: "Quantidade",
  str0022: "Observações",
  str0023: "Anexos",
  str0024: "Solicitante",
  str0025: "Filial solicitante",
  str0026: "Filial entrega",
  str0027: "Centro de custo",
  str0028: "Conta contábil",
  str0029: "Item contábil",
  str0030: "Classe valor",
  str0031: "Agrupar solicitações",
  str0032: "Iniciar cotação",
  str0033: "Insira o código da solicitação",
  str0034: "Insira o solicitante",
  str0035: "Insira a descrição do produto",
  str0036: "Insira a filial solicitante",
  str0037: "Insira a filial de entrega",
  str0038: "Insira o centro de custo",
  str0039: "Insira a conta contábil",
  str0040: "Insira o item contábil",
  str0041: "Insira a classe valor",
  str0042: "Limpar filtros",
  str0043: "Aplicar filtros",
  str0044: "Cotações",
  str0045: "Deseja realmente sair da aplicação?",
  str0046: "Lista de anexos",
  str0047: "Nome",
  str0048: "Descrição",
  str0049: "Anexo",
  str0050: "Clique para exibir lista de anexos",
  str0051: "Insira seu usuário",
  str0052: "Insira sua senha",
  str0053: "Informe o alias desejado",
  str0054: "Minhas rotinas",
  str0055: "Conheça as rotinas",
  str0056: "Pedido de compras",
  str0057: "Histórico de compras",
  str0058: "Indicadores",
  str0059: "solicitações de compras.",
  str0060: "Rotina para geração de cotações com base nas solicitações de compras cadastradas. Obtenha informações de solicitações em aberto, parcialmente atendidas e atendidas.",
  str0061: "Permite a atualização, exclusão e a análise de cotações considerando a melhor proposta entre os fornecedores de acordo com o preço, condições de pagamento e prazo de entrega.",
  str0062: "Permite a manutenção de Pedido de Compras gerados a partir das necessidades da empresa, representando um documento de formalização da compra entre empresa e o fornecedor.",
  str0063: "Gestão de Compras",
  str0064: "Acessar",
  str0065: "Solicitação de cotação",
  str0066: "Dados da cotação",
  str0067: "Grupo cotações",
  str0068: "Apelido do grupo de cotações",
  str0069: "Recebimento cotação",
  str0070: "Quantidade fornecedores",
  str0071: "Informações de entrega",
  str0072: "CNPJ",
  str0073: "Razão social",
  str0074: "Endereço de entrega",
  str0075: "Anexar arquivos",
  str0076: "Somente arquivos XMLs, PDF, Otcx.",
  str0077: "Limite de tamanho por arquivo: até 30 MB.",
  str0078: "Produtos solicitados",
  str0079: "Ordenação",
  str0080: "Solicitações agrupadas",
  str0081: "Esta informação não é enviada para o fornecedor.",
  str0082: "Código do produto",
  str0083: "Descrição do produto",
  str0084: "Unidade de medida",
  str0085: "Qtde. total",
  str0086: "Observação para o fornecedor",
  str0087: "Registro selecionado:",
  str0088: "Registros selecionados:",
  str0089: "Registro(s)",
  str0090: "Registros",
  str0091: "Não há produtos solicitados",
  str0092: "Fechar",
  str0093: "Voltar ao agrupamento de solicitações",
  str0094: "Seleção de Fornecedores / Participantes",
  str0095: "Próximo passo",
  str0096: "Novo fornecedor",
  str0097: "Olá,",
  str0098: "Logout realizado com sucesso!",
  str0099: "Sim",
  str0100: "Não",
  str0101: "Cancelar",
  str0102: "Fornecedores / Participantes selecionados",
  str0103: "Você ainda não selecionou fornecedores.",
  str0104: "Os últimos fornecedores são listados conforme as últimas notas fiscais de compras encontradas para os produtos desta cotação.",
  str0105: "Sugestão de fornecedor",
  str0106: "Últimos fornecedores",
  str0107: "Todos os fornecedores",
  str0108: "Busque por CNPJ, nome fantasia ou razão social...",
  str0109: "Mostrando ",
  str0110: " de ",
  str0111: " sugestão de fornecedor",
  str0112: " sugestões de fornecedores",
  str0113: "Código fornecedor",
  str0114: "Loja",
  str0115: "E-mail",
  str0116: "Telefone",
  str0117: "Grupos de produtos atendidos",
  str0118: "grupo de produtos",
  str0119: "Código grupo",
  str0120: "Descrição grupo",
  str0121: "Produtos cadastrados",
  str0122: "Sem informação",
  str0123: "Mostrar detalhes",
  str0124: "Atualizar contatos",
  str0125: "Detalhes do fornecedor",
  str0126: "Filtro sem resultados",
  str0127: "Deseja realmente cancelar esta operação?",
  str0128: "Fornecedores selecionados",
  str0129: "Trocar de Filial",
  str0130: "CPF",
  str0131: "Carregar mais resultados",
  str0132: "Digite o código do grupo",
  str0133: "Grupo",
  str0134: "Escolha o grupo de filiais desejado.",
  str0135: "Filial",
  str0136: "Escolha a filial desejada.",
  str0137: "Troca de filial",
  str0138: "Esses são todos os fornecedores cadastrados na sua base.",
  str0139: "Nenhuma filial selecionada.",
  str0140: "Número de fornecedores selecionados maior que o máximo permitido.",
  str0141: "Gerar cotação",
  str0142: "(Obrigatório)",
  str0143: "Número de caracteres inválido",
  str0144: "Remover",
  str0145: "Digite o apelido do grupo desta cotação",
  str0146: "Selecione o prazo máximo para o recebimento de cotações dos fornecedores.",
  str0147: "Valor inválido",
  str0148: "Quantidade de fornecedores que participarão da cotação.",
  str0149: "Baixar anexo",
  str0150: "Remover item da lista",
  str0151: "Itens selecionados",
  str0152: "Fornecedor",
  str0153: "Endereço",
  str0154: "Bairro",
  str0155: "Cep",
  str0156: "Nome fantasia",
  str0157: "Município",
  str0158: "Estado",
  str0159: "Confirma a criação da cotação de compra?",
  str0160: "Finalizando a solicitação da cotação",
  str0161: "Confirmar",
  str0162: "Fornecedores selecionados para o processo:",
  str0163: "País",
  str0164: "Formato inválido",
  str0165: "Enviar cotação por e-mail para o(s) fornecedor(es)?",
  str0166: "Enviar anexos para o fornecedor?",
  str0167: "Mais Detalhes",
  str0168: "Gráficos",
  str0169: "SC x PC",
  str0170: "Visão",
  str0171: "Compras",
  str0172: "Excluir anexo",
  str0173: "Ações",
  str0174: "Deseja realmente excluir este anexo?",
  str0175: "DDD",
  str0176: "E-mail principal",
  str0177: "Tipo de documento",
  str0178: "Avalia proposta vencida?",
  str0179: "Selecione o tipo de documento",
  str0180: "Pedido",
  str0181: "Contrato",
  str0182: "Selecione se avalia a proposta vencida",
  str0183: "Envio de e-mails e anexos",
  str0184: "Deseja cancelar o envio de e-mails e anexos?",
  str0185: "Processo finalizado com sucesso!",
  str0186: "Já existe uma cotação aberta para esta solicitação de compra!",
  str0187: "Arraste um arquivo ou clique aqui para selecionar",
  str0188: "Máximo de 10MB por arquivo",
  str0189: "Senha e/ou usuário inválido, verifique e tente novamente.",
  str0190: "Em andamento",
  str0191: "Falhou",
  str0192: "Concluído",
  str0193: "Excluir anexo",
  str0194: "Deseja excluir o anexo '{0}'? Esta ação é irreversível.",
  str0195: "Excluindo anexo...",
  str0196: "O anexo '{0}' foi excluído com sucesso!",
  str0197: "O formato do arquivo '{0}' não é aceito!",
  str0198: "O tamanho do arquivo '{0}' é superior a 10MB!",
  str0199: "Na fila",
  str0200: "Os filtros são aplicados apenas na visualização. Todos os itens já selecionados serão mantidos.",
  str0201: "Filtros aplicados",
  str0202: "Ver filtros",
  str0203: "Cotações solicitadas",
  str0204: "Cotação",
  str0205: "Apelido",
  str0206: "Prazo médio de entrega",
  str0207: "Status",
  str0208: "Pendente",
  str0209: "Pronto para analisar",
  str0210: "Em análise",
  str0211: "Finalizada",
  str0212: "Excluída",
  str0213: "Editar",
  str0214: "Analisar",
  str0215: "Continuar análise",
  str0216: "Visualizar",
  str0217: "cotações",
  str0218: "Excluir cotação",
  str0219: "Reabrir análise",
  str0220: "Busque por número ou apelido da cotação",
  str0221: "Opção do menu ainda em construção!",
  str0222: "Encontramos itens inválidos",
  str0223: "Mensagem de erro",
  str0224: "Não será possível iniciar uma cotação",
  str0225: "Itens inválidos não serão considerados na geração da cotação.",
  str0226: "Deseja continuar essa cotação sem os itens descritos acima?",
  str0227: "O processo de cotação foi cancelado.",
  str0228: "Cancelar cotação",
  str0229: "Este fornecedor já foi selecionado!",
  str0230: "Identificamos um problema para iniciar essa cotação",
  str0231: "Não será possível gerar essa cotação",
  str0232: "Editar cotação",
  str0233: "Detalhes",
  str0234: "Parcial",
  str0235: "Excluir",
  str0236: "Análise da cotação",
  str0237: "Total Frete",
  str0238: "Total Itens",
  str0239: "Valor a pagar",
  str0240: "Prazo de entrega",
  str0241: "Formalizar compra",
  str0242: "Detalhes da cotação",
  str0243: "Total da cotação",
  str0244: "Itens pendentes",
  str0245: "Voltar",
  str0246: "Descontos",
  str0247: "Impostos",
  str0248: "Frete",
  str0249: "Forma pagamento",
  str0250: "Condição pagamento",
  str0251: "Desqualificar fornecedor",
  str0252: "Enviar e-mail",
  str0253: "Observação",
  str0254: "Entrega",
  str0255: "Produtos solicitados",
  str0256: "Quantidade solicitada",
  str0257: "Quantidade disponível",
  str0258: "Valor unitário",
  str0259: "Valor total",
  str0260: "Critério mais importante",
  str0261: "Valor total",
  str0262: "Valor unitário",
  str0263: "Melhor preço",
  str0264: "Menor prazo",
  str0265: "Melhor nota",
  str0266: "Filtros",
  str0267: "Descontos",
  str0268: "Impostos",
  str0269: "Valor total da cotação",
  str0270: "Descontos aplicados",
  str0271: "Tipo de Frete",
  str0272: "Cond. Pagamento",
  str0273: "CIF",
  str0274: "FOB",
  str0275: "Por Terceiros",
  str0276: "Por Remetente",
  str0277: "Destinatário",
  str0278: "Sem Frete",
  str0279: "Detalhes Pagamento",
  str0280: "Frete item",
  str0281: "Total item",
  str0282: "Data entrega",
  str0283: "Endereço entrega",
  str0284: "Considerar item",
  str0285: "Desconto",
  str0286: "Salvar",
  str0287: "Cotações",
  str0288: "Carregando",
  str0289: "Desqualificar",
  str0290: "Atualizando cotação",
  str0291: "Finalizada",
  str0292: "Salvar anexo(s)",
  str0293: "Enviar e-mail(s)",
  str0294: "Cidade",
  str0295: "Tipo frete",
  str0300: "Dias para entrega",
  str0301: "Visualizar",
  str0302: "Qualificar",
  str0303: "Desqualificar",
  str0304: "Outras ações",
  str0305: "Dados da cotação: ",
  str0306: "Visualizando cotação: ",
  str0307: "Considera item",
  str0308: "É obrigatório o preenchimento da condição de pagamento.",
  str0309: "A quantidade de dias para entrega deve ser maior que zero.",
  str0310: "A quantidade disponível inserida é maior do que a quantidade solicitada.",
  str0311: "Há item sem definição de situação.",
  str0312: "Texto de observação a ser enviado ao fornecedor.",
  str0313: "Adicionar e-mail",
  str0314: "Para: ",
  str0315: "Completa",
  str0316: "Parcial",
  str0317: "Pendente",
  str0318: "Rejeitada",
  str0319: "Desqualificado",
  str0320: "Completa (E-mail)",
  str0321: "Parcial (E-mail)",
  str0322: "Deseja desqualificar este fornecedor?",
  str0323: "Deseja qualificar este fornecedor?",
  str0324: "E-mail inserido em duplicidade",
  str0325: "Tamanho máximo do campo é de ",
  str0326: "caracteres",
  str0327: "Tamanho máximo do campo ",
  str0328: "excedido.",
  str0329: "Anexo(s)",
  str0330: "E-mail inválido",
  str0331: "Novo anexo",
  str0332: "Arquivo",
  str0333: "Por Proposta Completa",
  str0334: "Por Item",
  str0335: "Média total",
  str0336: "Exibir fornecedores",
  str0337: "Ocultar fornecedores",
  str0338: "Itens não respondidos",
  str0339: "Atenção",
  str0340: "Existem itens que não foram respondidos por nenhum fornecedor",
  str0341: "Um ou mais itens não foram respondidos por nenhum fornecedor",
  str0342: "Caso queira continuar retire o(s) item(s) da cotação",
  str0343: "Ao clicar em não retirar apenas a visualizção por itens estará disponível",
  str0344: "Abaixo da média",
  str0345: "Acima da média",
  str0346: "Unidade",
  str0347: "Analisar por item",
  str0348: "Remover itens",
  str0349: "Ao clicar em sim apenas a análise por item ficará disponível.",
  str0350: "Ao clicar em sim os itens selecionados (itens não atendidos) serão removidos da cotação atual.",
  str0351: "Item",
  str0352: "Data estimada",
  str0353: "Média unidade",
  str0354: "Qtde. disponível",
  str0355: "Moeda",
  str0356: "Taxa da Moeda",
  str0357: "Não há fornecedores aptos para esta modalidade de análise. Utilize a opção por item",
  str0358: "Atenção esta ação é irreversível.",
  str0359: "Existem itens que não foram respondidos por nenhum fornecedor.",
  str0360: "Não há propostas completas para análise, deseja continuar a análise apenas por item ou remover itens não cotados?",
  str0361: "Excluir cotação",
  str0362: "Tem certeza que deseja excluir a cotação",
  str0363: "O desconto inserido é maior que o valor total do item.",
  str0364: "Gerando documentos",
  str0365: "Nenhum item vencedor foi selecionado.",
  str0366: "Na média",
  str0367: "Deseja confirmar a análise e gerar documentos com os vencedores selecionados?",
  str0368: "Formalizar a cotação",
  str0369: "Ordenar por:",
  str0370: "Dias",
  str0371: "Preço com impostos",
  str0372: "Impostos",
  str0373: "Valor IPI",
  str0374: "Valor ICMS",
  str0375: "Valor ISS",
  str0376: "ICMS Comple.",
  str0377: "Valor ICMS solidário",
  str0378: "Valor DIFAL",
  str0379: "Alíquota IPI",
  str0380: "Alíquota ICMS",
  str0381: "Alíquota ISS",
  str0382: "Aliq. Comp.",
  str0383: "Valores por item",
  str0384: "Porcentagem por item",
  str0385: "Impostos por item",
  str0386: "Calcular impostos",
  str0387: "Impostos totais",
  str0388: "Valores totais",
  str0389: "Valor bruto",
  str0390: "Valor líquido",
  str0391: "Não e possível aplicar desconto a um produto sem valor",
  str0392: "Base difal destino",
  str0393: "Difal Origem",
  str0394: "Difal Destino",
  str0395: "Valores base",
  str0396: "Base IPI",
  str0397: "Base ICMS",
  str0398: "Valor total dos itens",
  str0399: "Base ICMS solidário",
  str0400: "Impostos calculados com sucesso.",
  str0401: "Valor total do item",
  str0402: "Nenhum item da cotação foi respondido.",
  str0403: "A cotação não possui 100% dos itens atendidos (quantidade disponível menor que a solicitada ou valor unitário zerado).",
  str0404: "A cotação possui 100% dos itens atendidos.",
  str0405: "A cotação foi recusada pelo fornecedor.",
  str0406: "A cotação do fornecedor foi desqualificada.",
  str0407: "Cotação respondida por e-mail e não possui 100% dos itens atendidos (quantidade disponível menor que a solicitada ou valor unitário zerado).",
  str0408: "Cotação respondida por e-mail e possui 100% dos itens atendidos.",
  str0409: "Esta cotação não possui anexos.",
  str0410: "Serão enviados apenas os anexos informados na geração da cotação, não sendo possível anexar novos arquivos ao e-mail.",
  str0411: "Observação PC/CT",
  str0412: "Este item já foi selecionado!",
  str0413: "Encontramos um problema ao enviar e-mail. Verifique as configurações do Workflow!",
  str0414: "Necessário preencher a justificativa para os itens.",
  str0415: "O(s) iten(s) abaixo não possuem o menor valor, portanto, é necessário justificar a escolha.",
  str0416: "Importante",
  str0417: "A proposta escolhida não possui o menor valor, portanto, é necessário justificar a escolha.",
  str0418: "Necessário inserir uma justificativa.",
  str0419: "Adicionar observação",
  str0420: "Justificativa",
  str0421: "Todos",
  str0422: "Tipo de status",
  str0423: "Proposta",
  str0424: "Campos customizados",
  str0425: "Impostos por item",
  str0426: "Demais campos",
  str0427: "Nova proposta workflow",
  str0428: "Uma nova proposta será gerada, todos os valores já preenchidos serão zerados",
  str0429: "Quant. produtos",
  str0430: "Quant. fornecedores",
  str0431: "Exibir  histórico de propostas",
  str0432: "Ocultar  histórico de propostas",
  str0433: "Nova proposta manual",
  str0434: "Uma nova proposta será gerada, ao clicar em confirmar você será redirecionado para a próxima tela.",
  str0435: "Data de emissão",
  str0436: "Inconsistência no campo E-mail do fornecedor",
  str0437: "Despesas",
  str0438: "Seguro",
  str0439: "Código grupo de produto",
  str0440: "Descrição grupo de produto",
  str0441: "Insira o grupo de produto",
  str0442: "Insira a descrição do grupo de produto",
  str0443: "Grupos de produto diferentes",
  str0444: "Validar grupo de produto?",
  str0445: "Permitir selecionar apenas itens do mesmo grupo de produto (Considera apenas o grupo do primeiro item selecionado).",
  str0446: "Só é permitido selecionar itens sem grupo de produto.",
  str0447: "Só é permitido selecionar itens do grupo de produto",
  str0448: "Mapa da cotação",
  str0449: "Outros valores",
  str0450: "Tipo de entrada",
  str0451: "Código",
  str0452: "Código fiscal",
  str0453: "Gera duplicata",
  str0454: "Atualiza estoque",
  str0455: "Texto padrão",
  str0456: "Excluir proposta",
  str0457: "A proposta atual será excluída, deseja continuar?",
  str0458: "Valores médios",
  str0459: "Média Uni.",
  str0460: "O percentual de desconto máximo permitido é de",
  str0461: "O desconto inserido é maior que o valor total da cotação.",
  str0462: "Saldo",
  str0463: "Fornecedor já existente",
  str0464: "Para incluir um participante nesta solicitação de cotação, preencha os dados abaixo.",
  str0465: "Caso seja efetuado um pedido de compras com este participante, será necessário realizar o cadastro completo.",
  str0466: "Incluir fornecedor",
  str0467: "Fornecedor não cadastrado",
  str0468: "A cotação teve como ganhador um participante não cadastrado. Deseja cadastra-lo agora?",
  str0469: "Caso negativo esta cotação não será formalizada",
  str0470: "Sessão expirada. Faça o login novamente.",
  str0471: "Cadastro de fornecedor",
  str0472: "Outros",
  str0473: "Código Município",
  str0474: "DDI",
  str0475: "Agência",
  str0476: "Conta",
  str0477: "Condição",
  str0478: "Tipo",
  str0479: "O CNPJ informado deve ser alterado.",
  str0480: "O CPF informado deve ser alterado.",
  str0481: "CNPJ já cadastrado",
  str0482: "CPF já cadastrado",
  str0483: "Qtd. Respostas",
  str0484: "Gerar documentos",
  str0485: "Formalização",
  str0486: "Prop. Respondidas",
  str0487: "Prop. Descartadas",
  str0488: "Formalização da proposta",
  str0489: "Tipo do documento",
  str0490: "Contrato",
  str0491: "Pedido compra",
  str0492: "Contrato Único",
  str0493: "Contratos Separados",
  str0494: "Próximo contrato",
  str0495: "Salvar contrato",
  str0496: "Salvar contratos",
  str0497: "Adicionar Contrato",
  str0498: "Deseja gerar um único contrato para todos os fornecedores, separando-os em planilhas, ou contratos separados para cada fornecedor?",
  str0499: "Formalização das propostas",
  str0500: "Produto",
  str0501: "Preferência",
  str0502: "O fornecedor '{0}' não possui saldo para este produto.",
  str0503: "O item '{0}' não possui saldo.",
  str0504: "A escolha do fornecedor com melhor valor considera a quantidade disponível e composição dos seguintes valores:",
  str0505: "Informações",
  str0506: "Cálculos",
  str0507: "O fornecedor não possui estoque ou não vende os itens cotados.",
  str0508: "Produtos",
  str0509: "O código do fornecedor ou razão social já foram utilizados nesta cotação.",
  str0510: "Adicionar Fornecedor",
  str0511: "Medição Eventual",
  str0512: "Contrato Fixo",
  str0513: "Contato",
  str0514: "Novo participante",
  str0515: "Não foi encontrato fornecedor para a loja informada.",
  str0516: "Preencha o campo Código fornecedor ou Razão social.",
  str0517: "Formato do campo e-mail inválido.",
  str0518: "Não é possível adicionar um novo participante, pois a cotação está fora do prazo de recebimento.",
  str0519: "Enviar cotação por e-mail para o novo participante?",
  str0520: "Gerar",
  str0521: "Campos obrigatórios",
  str0522: "Cotação pendente.",
  str0523: "Cotação finalizada.",
  str0524: "Anexo salvo com sucesso.",
  str0525: "Erro ao salvar o anexo.",
  str0526: "É obrigatório o preenchimento da moeda.",
  str0527: "Somente fornecedores com propostas completas, ou seja, que responderam todos os itens, serão listados abaixo.",
  str0528: "Parâmetro e Arquivos",
  str0529: "Informe o diretório de trabalho do Workflow",
  str0530: "Faça o download abaixo e extraia os arquivos na pasta informada acima.",
  str0531: "Documentação oficial para consulta",
  str0532: "Novo Fluxo de Compras",
  str0533: "Dados do Servidor",
  str0534: "Informe o endereço (IP) público do servidor de workflow",
  str0535: "Informe a porta do servidor de workflow",
  str0536: "Nome do Ambiente onde o workflow é executado",
  str0537: "Nome/IP e porta do servidor de workflow",
  str0538: "Porta Multiprotocolo",
  str0539: "Finalização",
  str0540: "Ao confirmar, os dados serão salvos na base e o sistema irá criar um arquivo de modelo de Appserver.ini, para ser copiado no seu Appserver.ini oficial, conforme regras do seu ambiente.",
  str0541: "Sucesso",
  str0542: "O arquivo AppServer.ini abaixo é apenas uma sugestão, com os dados informados. Adapte ao .ini do ambiente real, conforme necessidade.",
  str0543: "Lembre-se: Coloque os arquivos .html baixados no local informado no campo diretório de trabalho do Workflow",
  str0544: "Parâmetros de e-mail - compradores",
  str0545: "Informe o endereço do servidor SMTP:",
  str0546: "Informe a porta do servidor SMTP:",
  str0547: "Informe o endereço do servidor POP 3 / IMAP:",
  str0548: "Informe a porta do servidor POP 3 / IMAP:",
  str0549: "Copiar texto",
  str0550: "Modelo de AppServer.ini",
  str0551: "Configuração - e-mail do comprador",
  str0552: "Protocolo de segurança utilizado no servidor",
  str0553: "Preencher com o nome/IP do servidor SMTP de e-mail utilizado. Caso esteja em branco ou não tenha informação, consulte o departamento técnico da empresa.",
  str0554: "Preencher com a porta do servidor SMTP de e-mail utilizado. Caso esteja em branco ou não tenha informação, consulte o departamento técnico da empresa.",
  str0555: "Preencher com o nome/IP do servidor POP3/IMAP de e-mail utilizado. Caso esteja em branco ou não tenha informação, consulte o departamento técnico da empresa.",
  str0556: "Preencher com a porta do servidor POP3/IMAP de e-mail utilizado. Caso esteja em branco ou não tenha informação, consulte o departamento técnico da empresa.",
  str0557: "Tipo de protocolo de autenticação do servidor de e-mail: SSL, TLS ou Nenhum",
  str0558: "Caixa de e-mail criada.",
  str0559: "Conta de E-mail comprador",
  str0560: "E-mail do comprador",
  str0561: "Nome do comprador que irá no e-mail",
  str0562: "E-mail do comprador que irá no e-mail - campo 'De:'",
  str0563: "Autenticação",
  str0564: "Autenticação do usuário (conta de e-mail)",
  str0565: "Senha de autenticação (senha do e-mail)",
  str0566: "Servidores SMTP e POP 3/IMAP",
  str0567: "Endereço do servidor SMTP",
  str0568: "Porta do servidor SMTP",
  str0569: "Endereço do servidor POP 3/IMAP",
  str0570: "Porta do servidor POP 3/IMAP",
  str0571: "Ao prosseguir, será criada a nova caixa de e-mail com as informações inseridas.",
  str0572: "Porta Multiprotocolo (MultiProtocolPort) existe no INI e está ativa.",
  str0573: "Porta Multiprotocolo (MultiProtocolPort) não existe no INI ou está inativa (valor 0).",
  str0574: "Ocorreu um erro na criação da caixa de e-mail: ",
  str0575: "Informe o diretório onde serão gravados os arquivos gerados pelo workflow. Por exemplo, caso o caminho seja direto na pasta Workflow, informe apenas \\workflow.",
  str0576: " Caso tenha alias virtual ou subdiretório para o workflow, como a pasta ABC, dentro da pasta Workflow, informe \\workflow\\abc.",
  str0577: "Código SC",
  str0578: "Solicitações de Compras relacionadas",
  str0579: "Configuração Workflow",
  str0580: "É Considerado os impostos IPI e ICMS Solidário como critério para o melhor preço.",
  str0581: "Total sem impostos",
  str0582: "Total com impostos",
  str0583: "Melhor preço sem impostos",
  str0584: "Melhor preço com impostos",
  str0585: "Pedidos de Compras gerados",
  str0586: "Contratos gerados",
  str0587: "Detalhes do orçamento",
  str0588: "Número PC",
  str0589: "Número Contrato",
  str0590: "Tipo Contrato",
  str0591: "Valor",
  str0592: "Data Inicial",
  str0593: "Vigência",
  str0594: "Exibir fornecedores vinculados ao produto",
  str0595: "Ocultar fornecedores vinculados ao produto",
  str0596: "Seleção de Fornecedores x Produto",
  str0597: "Seleção de Fornecedores",
  str0598: "CNPJ/CPF",
  str0599: "Os arquivos acima também podem ser encontrados na seção Workflow da documentação oficial.",
  str0600: "Clique aqui",
  str0601: "Download de arquivos",
  str0602: "Observação da solicitação",
  str0603: "Sobre o Wizard",
  str0604: "Esse wizard visa facilitar a criação do Workflow, utilizado no NFC, para envio das propostas via e-mail.",
  str0605: "Em cada etapa do wizard, preencha com as informações solicitadas, para que no final do wizard, as informações sejam gravadas no sistema e exibido um arquivo ",
  str0606: " AppServer.ini de modelo, para que possa comparar e fazer os ajustes necessários no arquivo AppServer.ini do ambiente em que o workflow vai ser implementado.",
  str0607: "Além disso, é possível inserir como padrão os endereços dos servidores SMTP e POP3/IMAP utilizados na organização, para que cada comprador que for utilizar o NFC, possa configurar seu e-mail de forma autônoma, ",
  str0608: " através do wizard de configuração de e-mail do comprador.",
  str0609: "Dados gravados e modelo de AppServer.ini gerado.",
  str0610: "Caixa de e-mail alterada",
  str0611: "Encontrado o(s) seguinte(s) problema(s): ",
  str0612: "Esse wizard visa facilitar a criação da caixa de e-mail do comprador, usado no Workflow do NFC, para envio das propostas via e-mail, caso o parâmetro MV_PGCWF esteja ativo.",
  str0613: "Em cada etapa do wizard, preencha com as informações solicitadas. Caso na etapa do endereço dos servidores SMTP e POP3/IMAP tenha dúvidas, consulte o departamento técnico da sua organização.",
  str0614: "No final, caso as informações estejam corretas, o sistema irá criar a caixa de e-mail do comprador no Workflow, disparando as propostas por meio dessa caixa de correio.",
  str0615: "Observações adicionadas na solicitação de compras. Esta informação não será enviada aos fonecedores.",
  str0616: "Digite observações que serão enviadas aos fornecedores (Opcional).",
  str0617: "Manter valores da proposta atual?",
  str0618: "Código comprador",
  str0619: "Nome comprador",
  str0620: "Busque por comprador",
  str0621: "Quantidade a ser adquirida:",
  str0622: "O valor informado ",
  str0623: " é maior que o saldo disponível ",
  str0624: "O campo Quantidade será preenchido com o valor do saldo disponível do item.",
  str0625: "A quantidade deve ser maior que zero.",
  str0626: "O Pedido de Compra e/ou Contrato relacionado à cotação foram excluídos do sistema.",
  str0627: "Atendido Parcialmente",
  str0628: "From A - Z",
  str0629: "From Z - A",
  str0630: "Quantidade no Fornecedor",
  str0631: "Qtd. disponível Fornecedor",
  str0632: "Próxima etapa",
  str0633: "Etapa anterior",
  str0634: "Erro ao obter condições de pagamento:",
  str0635: "Selecionar condições de pagamento?",
  str0636: "Caso queira optar por não selecionar condições de pagamento, todas as condições habilitadas para envio no WF serão automaticamente enviadas.",
  str0637: "Compra Centralizada",
  str0638: "Filial Centralizadora",
  str0639: "Filial e CNPJ",
  str0640: "Itens das Filiais a serem centralizadas",
  str0641: "Itens que serão centralizados",
  str0642: "Observação PC",
  str0643: "Nessa tabela, ficam os itens selecionados que serão migrados para a filial centralizadora, ou seja, a filial logada.",
  str0644: "Aqui, é exibido todas as solicitações de compras, independente da filial. Ou seja, é exibido as solicitações de todas as filiais do sistema.",
  str0645: "Selecione a filial Centralizadora",
  str0646: "Centralizar",
  str0647: "Tentar novamente",
  str0648: "Nome da Caixa de e-mail:",
  str0649: "Informe o nome da caixa de e-mail. Utilize apenas letras, números e sublinhado.",
  str0650: "Por padrão, o nome será o e-mail do usuário - constando somente letras, números e sublinhado (_) - ocultando demais caracteres.",
  str0651: "O nome da caixa de e-mail é gravado apenas na inclusão. Se já existir, qualquer alteração nesse campo é ignorada.",
  str0652: "Tem certeza que deseja remover o item: ",
  str0653: "Filtro do comprador",
  str0654: "Itens da cotação",
  str0655: "Expandir Visualização - Produtos Solicitados",
  str0656: "Substituição do aprovador",
  str0657: "Aprovador",
  str0658: "Substituto",
  str0659: "Substituir e transferir permanente",
  str0660: "Selecione o aprovador:",
  str0661: "Transferência temporária",
  str0662: "Período de ausência",
  str0663: "Todos os documentos e grupos de aprovação serão transferidos para o aprovador substituto permanentemente.",
  str0664: "imagem tela home",
  str0665: "Imagem transferência de documentos",
  str0666: "Num. Documentos",
  str0667: "Busque por número do documento",
  str0668: "Selecione o aprovador substituto:",
  str0669: "Autorização de entrega",
  str0670: "Contato Parceria",
  str0671: "Documento Garantia",
  str0672: "Medição",
  str0673: "Revisão",
  str0674: "Documento de Entrada",
  str0675: "Solicitação ao Armazém ",
  str0676: "Solicitação de Transferência",
  str0677: "Documento Agro",
  str0678: "Transferência de documentos",
  str0679: "Deseja transferir os documentos selecionados para o aprovador substituto?",
  str0680: "Documentos",
  str0681: "Transfere os documentos direcionados ao aprovador para o substituto por determinado tempo de ausência, que deve ser previamente estabelecido.",
  str0682: "Transfere todos os documentos do aprovador, que estão aguardando a liberação, incluindo os grupos de aprovação para o aprovador substituto.",
  str0683: "Migrar cotações",
  str0684: "O objetivo desta rotina é realizar a migração de cotações geradas pela rotina de 'Gera cotações, Atualiza cotação e Analisa cotação' para o ",
  str0685: "Novo Fluxo de Compras.",
  str0686: "Deve-se preencher a data inicial e final de emissão para filtrar as cotações a serem migradas.",
  str0687: "Atenção:",
  str0688: "Esta ação não pode ser revertida. O processamento será realizado em segundo plano.",
  str0689: "Data inicial",
  str0690: "Data final",
  str0691: "Receber notificação ao término do processamento?",
  str0692: "Sim",
  str0693: "Não",
  str0694: "Necessário possuir e-mail pré-configurado no ambiente.",
  str0695: "Deseja transferir permanentemente todos os documentos do aprovador para o aprovador substituto?",
  str0696: "Após a confirmação os documentos transferidos poderão ser consultados na lista do aprovador substituto.",
  str0697: "Nenhum",
  str0698: "Esta caixa de e-mail já foi cadastrada.",
  str0699: "Envio de e-mails e anexos",
  str0700: "Receber notificação da resposta?",
  str0701: "Notifica resumo para o fornecedor?",
  str0702: "Quando o fornecedor responder à proposta, você receberá um e-mail informando que a resposta foi enviada.",
  str0703: "Ao responder à proposta, o fornecedor receberá um e-mail contendo um resumo dos itens por ele respondidos.",
  str0704: "A Justicativa deve conter apenas ",
  str0705: " caracteres.",
  str0706: "Transferência pontual",
  str0707: "Transfere os documentos selecionados de forma pontual para o aprovador substituto.",
  str0708: "Compradores",
  str0709: "Defina os tipos de documentos para o grupo de aprovação:",
  str0710: "Grupo",
  str0711: "Aprovadores",
  str0712: "Fluxo de Aprovação",
  str0713: "Teste de Fluxo",
  str0714: "Concluído com sucesso",
  str0715: "Entidades contábeis",
  str0716: "O item",
  str0717: "não foi preenchido.",
  str0718: "Os itens",
  str0719: "não foram preenchidos.",
  str0720: "Não é permitido itens com informações repetidas. Verifique os itens",
  str0721: "Preencha os campos Grupo e Descrição.",
  str0722: "e",
  str0723: "Defina o grupo de aprovação:",
  str0724: "Este grupo irá utilizar entidades contábeis?",
  str0725: "Habilita entidades contábeis",
  str0726: "Defina as entidades contábeis para o grupo de aprovação:",
  str0727: "Adicionar",
  str0728: "Grupos de aprovação",
  str0729: "Busque pelo nome ou código do grupo",
  str0730: "Num. Pedido de Compra",
  str0731: "Existem itens que necessitam de intervenção manual, pois não foi possível selecionar automaticamente o melhor valor, pois algum dos fornecedores estão oferecendo quantidade menor que a solicitada.",
  str0732: "Os itens que necessitam de atenção estão listados abaixo:",
  str0733: "Continuar",
  str0734: "Ao clicar em continuar, será aberta a tela de configuração de preferências do contrato para finalizar a inclusão do mesmo.",
  str0735: "Wizard - Grupo de Aprovação",
  str0736: "Este passo do wizard tem como objetivo preencher as informações do grupo de aprovação. Nele, é possível definir o código, a descrição, os tipos de documentos e as entidades contábeis.",
  str0737: "Remover itens selecionados",
  str0738: "É necessário adicionar ao menos um item na tabela de entidades contábeis.",
  str0739: "Defina os aprovadores para o grupo de aprovação:",
  str0740: "Aprovadores cadastrados",
  str0741: "Aprovadores do grupo de aprovação",
  str0742: "É necessário adicionar ao menos um aprovador para prosseguir.",
  str0743: "Não é possível selecionar um aprovador que já foi adicionado.",
  str0744: "O código do superior não pode ser igual ao do aprovador.",
  str0745: "Preencha todos os campos obrigatórios.",
  str0746: "Defina o fluxo de aprovação para o grupo:",
  str0747: "Defina o tipo de compras vinculado ao aprovador:",
  str0748: "Este tipo de compra já foi inserido.",
  str0749: "Preencha o campo",
  str0750: "Preencha o item que está em branco antes de adicionar uma nova linha.",
  str0751: "Defina os tipos de documentos para o teste de fluxo: ",
  str0752: "Aprovação por documento",
  str0753: "Aprovação por item",
  str0754: "Validar",
  str0755: "Valor documento",
  str0756: "Clique em detalhes para visualizar o teste de fluxo: ",
  str0757: "Não foi gerado teste de fluxo para este tipo de documento.",
  str0758: "Selecione ao menos um tipo de documento para gerar o teste de fluxo.",
  str0759: "Novo Grupo",
  str0760: "Finalizar",
  str0761: "Bloqueado (aguardando outros níveis)",
  str0762: "Aguardando liberação do usuário",
  str0763: "Liberado pelo usuário",
  str0764: "Deseja gravar o grupo de aprovação?",
  str0765: "A configuração do grupo de aprovação foi concluída com sucessso.",
  str0766: "Solicitação de Compra",
  str0767: "Esta modalidade não esta habilitada para propostas geradas pelo Portal do Fornecedor",
  str0768: "Observação do fornecedor",
  str0769: "Observação fornecedor",
  str0770: "Responder",
  str0771: "Relação com o Fornecedor",
  str0772: "Agradecemos o interesse nesta funcionalidade!",
  str0773: "Neste momento será muito importante entender como funciona a gestão de relacionamento com fornecedores (SRM) na sua empresa. ",
  str0774: "Preparamos uma breve pesquisa com 5 questões e a sua ajuda será fundamental para entendermos o valor do SRM para a sua operação.",
  str0775: "Poderia nos ajudar? Não vai levar 5 minutos e pode deixar que as respostas recebidas serão apenas para uso interno.",
  str0776: "Agradecemos por participar do processo de evolução do módulo de Compras!",
  str0777: "Clique em ",
  str0778: "para acessar a pesquisa.",
  str0779: "A sequência de entidades contábeis informada no produto",
  str0780: "não existe no grupo de aprovação.",
  str0781: "Grupo de produtos",
  str0782: "UM",
  str0783: "Armazém",
  str0784: "Num. Cotação",
  str0785: "Valor Item",
  str0786: "Cód. Solicitação",
  str0787: "Busque pelo código de produto",
  str0788: "Qtd. Disponível",
  str0789: "Qtd. Entrada Prevista",
  str0790: "Últ. Preço de Compra",
  str0791: "Últ. Data de Compra",
  str0792: "Maior Qtd. Comprada",
  str0793: "Prazo médio de Entrega",
  str0794: "PCs em aberto",
  str0795: "PCs entregues",
  str0796: "Busque pelo código da Solicitação",
  str0797: "Busque pelo código da Cotação",
  str0798: "Busque pelo código do Fornecedor",
  str0799: "Solicitações de Compras",
  str0800: "Receber e-mail quando o(s) fornecedor(es) responder(em) à proposta?"
};
