/* tslint:disable */
// TOTVS TypeScript translaton resource
// Reference date : 20240930

export const literalsRuRU = {
  str0001: '',
  str0002: "Потребности",
  str0003: "Начать",
  str0004: "Коммерческое предложение",
  str0005: "Заказ на закупку",
  str0006: "История закупок",
  str0007: "Индикаторы",
  str0008: "На рассмотрении",
  str0009: "Частично выполнено",
  str0010: "Выполнено",
  str0011: "Выбрать период",
  str0012: "Поиск по коду МТР",
  str0013: "Расширенный поиск",
  str0014: "Выход",
  str0015: "Заявка",
  str0016: "Запрос МТР",
  str0017: "Код МТР",
  str0018: "Описание МТР",
  str0019: "Нужная дата",
  str0020: "Единица измерения",
  str0021: "Количество",
  str0022: "Примечания",
  str0023: "Вложения",
  str0024: "Заявитель",
  str0025: "Филиал заявителя",
  str0026: "Филиал поставки",
  str0027: "МВЗ",
  str0028: "Счет главной книги",
  str0029: "Объект учета",
  str0030: "Статья",
  str0031: "Групповые запросы",
  str0032: "Начальное ценовое предложение",
  str0033: "Введите код заявщика",
  str0034: "Укажите заявителя",
  str0035: "Добавьте описание МТР",
  str0036: "Укажите филиал заявщика",
  str0037: "Укажите филиал доставки",
  str0038: "Укажите МВЗ",
  str0039: "Укажите счет главной книги",
  str0040: "Укажите объект учета",
  str0041: "Укажите статью",
  str0042: "Очистить фильтры",
  str0043: "Применить фильтры",
  str0044: "Коммерческое предложение",
  str0045: "Выйти из приложения?",
  str0046: "Список прикрепленных файлов",
  str0047: "Наименование",
  str0048: "Описание",
  str0049: "Вложение",
  str0050: "Нажмите для отображения списка вложений",
  str0051: "Укажите пользователя",
  str0052: "Укажите пароль",
  str0053: "Укажите обозначение",
  str0054: "Мои процедуры",
  str0055: "Ознакомьтесь с процедурами",
  str0056: "Заказ на закупку",
  str0057: "История закупок",
  str0058: "Индикаторы",
  str0059: "Заявки на закупку",
  str0060: "Процедура формирования предложений на основе зарегистрированных заявок на закупку. Получение информации из открытых, частично обработанных и обработанных заявок.",
  str0061: "Позволяет обновлять, удалять и анализировать предложения, определяя лучшее предложение среди поставщиков по цене, условиям оплаты и срокам поставки",
  str0062: "Позволяет вести заказы на закупку, сформированные в соответствии с потребностями компании, представляющие собой документ, формализующий закупку между компанией и поставщиком.",
  str0063: "Управление Закупками",
  str0064: "Доступ",
  str0065: "Запрос предложения",
  str0066: "Дата КП",
  str0067: "Группа предложений",
  str0068: "Название группы предложений",
  str0069: "Получение предложения",
  str0070: "Количество поставщиков",
  str0071: "Информация о поставке",
  str0072: "ИНН юридического лица",
  str0073: "Наименование",
  str0074: "Адрес поставки",
  str0075: "Прикрепить файлы",
  str0076: "Только файлы в формате XML, PDF, Otcx",
  str0077: "Ограничение по размеру одного файла: до 30 MB.",
  str0078: "Затребованные МТР",
  str0079: "Позиции",
  str0080: "Сгруппированные заявки",
  str0081: "Эта информация не отправляется поставщику",
  str0082: "Код МТР",
  str0083: "Описание МТР",
  str0084: "Единица измерения",
  str0085: "Общее количество",
  str0086: "Примечания для поставщика",
  str0087: "Выбранная запись:",
  str0088: "Выбранные записи:",
  str0089: "Запись(и)",
  str0090: "Записи",
  str0091: "Нет запрашиваемых МТР",
  str0092: "Закрыть",
  str0093: "Вернуться к группированию заявок",
  str0094: "Выбор поставщиков/участников",
  str0095: "Следующий шаг",
  str0096: "Новый поставщик",
  str0097: "Здравствуйте,",
  str0098: "Выход из системы завершен успешно!",
  str0099: "Да",
  str0100: "Нет",
  str0101: "Отменить",
  str0102: "Выбранные поставщики/участники",
  str0103: "Вы еще не выбрали поставщика",
  str0104: "Последние поставщики перечислены в соответствии с последними найденными счетами на закупку МТР в этой совокупности",
  str0105: "Предложения по поставщикам",
  str0106: "Последние поставщики",
  str0107: "Все поставщики",
  str0108: "Поиск по ИНН, торговой марке или корпоративному названию...",
  str0109: "Показать",
  str0110: "из",
  str0111: "предложение поставщика",
  str0112: "предложение поставщиков",
  str0113: "Код поставщика",
  str0114: "Отдел",
  str0115: "E-mail",
  str0116: "Номер телефона",
  str0117: "Соответствующая группа МТР",
  str0118: "группа МТР",
  str0119: "Код группы",
  str0120: "Описание группы",
  str0121: "Зарегистрированные МТР",
  str0122: "Без информации",
  str0123: "Показать сведения",
  str0124: "Обновить контакты",
  str0125: "Сведения о поставщике",
  str0126: "Фильтр без результатов",
  str0127: "Отменить операцию?",
  str0128: "Выбранные поставщики",
  str0129: "Изменить филиал",
  str0130: "Номер социального страхования",
  str0131: "Загрузить больше результатов",
  str0132: "Введите код группы",
  str0133: "Группа",
  str0134: "Выберите нужную группу филиалов",
  str0135: "Филиал",
  str0136: "Введите нужный филиал",
  str0137: "Заменить филиал",
  str0138: "Это все поставщики, зарегистрированные в базе",
  str0139: "Филиал не выбран",
  str0140: "Количество отобранных поставщиков больше максимально допустимого",
  str0141: "Сформировать коммерческое предложение",
  str0142: "(Обязательно)",
  str0143: "Количество символов недопустимо",
  str0144: "Удалить",
  str0145: "Введите наименование группы предложений",
  str0146: "Выберите максимальный срок получения предложений от поставщиков",
  str0147: "Недопустимое значение",
  str0148: "Количество поставщиков, которые предоставят предложения",
  str0149: "Загрузить вложение",
  str0150: "Удалить позицию МТР из списка",
  str0151: "Выбранные позиции",
  str0152: "Поставщик",
  str0153: "Адрес",
  str0154: "Район",
  str0155: "Почтовый код",
  str0156: "Наименование",
  str0157: "Город",
  str0158: "Страна",
  str0159: "Подтвердить создание коммерческого предложения?",
  str0160: "Завершение запроса предложений",
  str0161: "Подтвердить",
  str0162: "Поставщики, выбранные для участия в процессе:",
  str0163: "Страна",
  str0164: "Недопустимый формат",
  str0165: "Отправлять предложения поставщикам по электронной почте?",
  str0166: "Отправить вложения поставщику?",
  str0167: "Подробнее",
  str0168: "Схемы",
  str0169: "Зая. х Зак.",
  str0170: "Просмотр",
  str0171: "Закупки",
  str0172: "Удалить вложение",
  str0173: "Действия",
  str0174: "Удалить это вложение?",
  str0175: "Код города",
  str0176: "Основной e-mail",
  str0177: "Тип документа",
  str0178: "Оценить просроченное предложение?",
  str0179: "Выберите тип документа",
  str0180: "Позиции",
  str0181: "Договор",
  str0182: "Укажите, нужно ли оценивать просроченное предложение",
  str0183: "Отправка сообщений и приложений",
  str0184: "Отменить отправку сообщений и приложений?",
  str0185: "Процесс успешно завершен!",
  str0186: "Для этой заявки на закупку уже существует открытое предложение!",
  str0187: "Перетащите файл или нажмите здесь, чтобы выбрать",
  str0188: "Максимум 10 МБ на файл",
  str0189: "Неверное имя пользователя и/или пароль, проверьте и повторите попытку",
  str0190: "В процессе",
  str0191: "Сбой",
  str0192: "Завершено",
  str0193: "Удалить вложение",
  str0194: "Удалить вложение  '{0}'? Это действие не может быть отменено.",
  str0195: "Вложение удаляется...",
  str0196: "Вложение '{0}' успешно удалено!",
  str0197: "Формат файла '{0}' не допустим!",
  str0198: "Размер файла '{0}' превышает 10 МБ!",
  str0199: "На очереди",
  str0200: "Фильтры применяются только к просмотру. Все выбранные элементы будут сохранены.",
  str0201: "Примененные фильтры",
  str0202: "См. фильтры",
  str0203: "Запрошенные предложения",
  str0204: "Коммерческое предложение",
  str0205: "Обозначение",
  str0206: '',
  str0207: "Статус",
  str0208: "На рассмотрении",
  str0209: "Готовы к анализу",
  str0210: "Анализируются",
  str0211: "Завершено",
  str0212: "Удалено",
  str0213: "Редактировать",
  str0214: "Анализировать",
  str0215: "Продолжить анализ",
  str0216: "Предпросмотр",
  str0217: "предложения",
  str0218: "Удалить предложение",
  str0219: "Повторный анализ",
  str0220: "Поиск по номеру предложения или наименованию",
  str0221: "Меню еще в разработке!",
  str0222: "Найдены недопустимые МТР",
  str0223: "Сообщение об ошибке",
  str0224: "Невозможно начать предложение",
  str0225: "Недействительные МТР не будут учитываться при составлении коммерческого предложения",
  str0226: "Продолжить это предложение без описанных выше МТР?",
  str0227: "Процесс предложения был отменен",
  str0228: "Отменить предложение",
  str0229: "Этот поставщик уже выбран!",
  str0230: "При запуске этого предложения была обнаружена проблема",
  str0231: "Невозможно сгенерировать это предложение",
  str0232: "Редактировать предложение",
  str0233: "Подробности",
  str0234: "Частичный",
  str0235: "Удалить",
  str0236: "Анализ предложения",
  str0237: "Всего доставлено",
  str0238: "Итого",
  str0239: "Сумма к оплате",
  str0240: "Срок доставки",
  str0241: "Оформить закупку",
  str0242: "Детали предложения",
  str0243: "Общая сумма предложения",
  str0244: "МТР на рассмотрении",
  str0245: "Вернуть",
  str0246: "Скидки",
  str0247: "Налоги",
  str0248: "Фрахт",
  str0249: "Способ платежа",
  str0250: "Условие платежа",
  str0251: "Отклонить поставщика",
  str0252: "Отправить сообщение",
  str0253: "Примечание",
  str0254: "Поставка",
  str0255: "Затребованные МТР",
  str0256: "Запрошенное количество",
  str0257: "Запрашиваемое количество",
  str0258: "Стоимость за единицу",
  str0259: "Общая стоимость",
  str0260: "Основной критерий",
  str0261: "Общая стоимость",
  str0262: "Стоимость единицы",
  str0263: "Лучшая цена",
  str0264: "Кратчайший срок",
  str0265: "Высший балл",
  str0266: "Фильтры",
  str0267: "Скидки",
  str0268: "Налоги",
  str0269: "Общая стоимость предложения",
  str0270: "Применяемые скидки",
  str0271: "Тип поставки",
  str0272: "Условие платежа",
  str0273: "CIF",
  str0274: "FOB",
  str0275: "Третьими лицами",
  str0276: "Отправителем",
  str0277: "Получатель",
  str0278: "Не доставляется",
  str0279: "Детали платежа",
  str0280: "Поставка товара",
  str0281: "Всего МТР",
  str0282: "Дата поставки",
  str0283: "Адрес поставки",
  str0284: "Рассмотреть МТР",
  str0285: "Скидка",
  str0286: "Сохранить",
  str0287: "Предлжения",
  str0288: "Загрузка",
  str0289: "Снять с учета",
  str0290: "Обновление предложения",
  str0291: "Завершено",
  str0292: "Сохранить вложение(я)",
  str0293: "Отправить электронную почту",
  str0294: "Город",
  str0295: "Тип фрахта",
  str0300: "Дни на доставку",
  str0301: "Просмотр",
  str0302: "Квалифицировать",
  str0303: "Снять с учета",
  str0304: "Другие действия",
  str0305: "Данные предложения:",
  str0306: "Просмотр предложения:",
  str0307: "Рассмотреть МТР",
  str0308: "Введите условие платежа",
  str0309: "Количество дней для доставки должно быть больше нуля",
  str0310: "Введенное доступное количество больше, чем запрошенное",
  str0311: "МТР существует без определения статуса",
  str0312: "Текст примечания для отправки поставщику",
  str0313: "Добавить электронную почту",
  str0314: "Кому:",
  str0315: "Завершено",
  str0316: "Частичный",
  str0317: "На рассмотрении",
  str0318: "Отклонено",
  str0319: "Дисквалифицирован",
  str0320: "Завершить (E-mail)",
  str0321: "Частичный (E-mail)",
  str0322: "Дисквалифицировать поставщика?",
  str0323: "Квалифицировать поставщика?",
  str0324: "E-mail дублируется",
  str0325: "Максимальный размер поля составляет",
  str0326: "символов",
  str0327: "Максимальный размер поля",
  str0328: "превышает",
  str0329: "Вложение(я)",
  str0330: "Неверный e-mail",
  str0331: "Новое вложение",
  str0332: "Файл",
  str0333: "По полному предложению",
  str0334: "По МТР",
  str0335: "Общее среднее",
  str0336: "Показать поставщиков",
  str0337: "Скрыть поставщиков",
  str0338: "МТР без ответа",
  str0339: "Примечание",
  str0340: "Имеются МТР, на которые не ответил ни один поставщик",
  str0341: "Ни один из поставщиков не ответил по одному или нескольким МТР",
  str0342: "Чтобы продолжить, удалите позиции МТР из предложения",
  str0343: "Если вы нажмете кнопку Не удалять, будет доступен только просмотр по МТР",
  str0344: "Ниже среднего",
  str0345: "Выше среднего",
  str0346: "Подразделение",
  str0347: "Анализ по МТР",
  str0348: "Удалить МТР",
  str0349: "Если вы нажмете Да, будет доступен только анализ по МТР",
  str0350: "Если вы нажмете Да, все выбранные МТР (не обслуживаемые) будут удалены из текущего предложения",
  str0351: "МТР",
  str0352: "Расчетная дата",
  str0353: "Среднее значение на единицу",
  str0354: "Доступное количество",
  str0355: "Валюта",
  str0356: "Курс валюты",
  str0357: "There are no suppliers for this analysis mode. Use the option by item",
  str0358: "Это действие нельзя отменить",
  str0359: "Имеются позиции МТР, на которые не ответил ни один поставщик",
  str0360: "Полноценных предложений для анализа не существует, продолжить анализировать только по МТР или удалить не участвующие в предложении МТР?",
  str0361: "Удалить предложение",
  str0362: "Вы уверены, что хотите удалить предложение",
  str0363: "Введенная скидка больше, чем общая стоимость товара",
  str0364: "Создание документов",
  str0365: "Победитель не выбран",
  str0366: "В среднем",
  str0367: "Подтвердить результаты анализа и подготовить документы с выбранными победителями?",
  str0368: "Официально оформите предложение",
  str0369: "Сортировать по:",
  str0370: "Дни",
  str0371: "Цена плюс налоги",
  str0372: "Налоги",
  str0373: "Значение IPI",
  str0374: "Значение ICMS",
  str0375: "Значение ISS",
  str0376: "Добавочный ICMS",
  str0377: "ICMS общее значение",
  str0378: "Значение DIFAL",
  str0379: "Ставка IPI",
  str0380: "Ставка ICMS",
  str0381: "Ставка ISS",
  str0382: "Добавочная ставка",
  str0383: "Значение по МТР",
  str0384: "Процент по МТР",
  str0385: "Налоги по МТР",
  str0386: "Рассчитать налоги",
  str0387: "Всего налогов",
  str0388: "Итоговые стоимость",
  str0389: "Валовая стоимость",
  str0390: "Чистая стоимость",
  str0391: "Невозможно применить скидку к товару без стоимости",
  str0392: "Целевая база difal",
  str0393: "Источник difal",
  str0394: "Целевой difal",
  str0395: "Базовые значения",
  str0396: "Базовое значение IPI",
  str0397: "Налоговая база ICMS",
  str0398: "Общая стоимость товаров",
  str0399: "Общая база ICMS",
  str0400: "Расчет налогов произведен успешно",
  str0401: "Общая стоимость товара",
  str0402: "Ни по одной МТР предложения не был получен ответ",
  str0403: "Предложение не удовлетворяет 100% МТР (имеющееся количество меньше запрашиваемого, или обнулена стоимость единицы товара)",
  str0404: "Предложение удовлетворяет 100% МТР",
  str0405: "Предложение отклонено поставщиком",
  str0406: "Предложение поставщика дисквалифицировано",
  str0407: "Предложение получено по электронной почте и не удовлетворяет 100% МТР (количество в наличии меньше, чем запрошено, или обнулена стоимость единицы товара)",
  str0408: "Предложение получено по электронной почте, удовлетворяет 100% МТР",
  str0409: "Данное предложение не имеет вложений",
  str0410: "Будут отправлены только те вложения, которые были прикреплены при создании коммерческого предложения, новые файлы к письму прикрепить нельзя",
  str0411: "Примечание к заказу на закупку/договору",
  str0412: "МТР уже выбран!",
  str0413: "Мы обнаружили проблему при отправке письма. Проверьте настройки!",
  str0414: "Введите обоснование по МТР",
  str0415: "Выбраны МТР не по самой низкой стоимости , поэтому выбор должен быть обоснован",
  str0416: "Важно",
  str0417: "Выбрано предложение не с наименьшей стоимостью, поэтому выбор должен быть обоснован",
  str0418: "Введите обоснование",
  str0419: "Добавить примечание",
  str0420: "Обоснование",
  str0421: "Все",
  str0422: "Тип статуса",
  str0423: "Предложение",
  str0424: "Настраиваемые поля",
  str0425: "Налоги по МТР",
  str0426: "Другие поля",
  str0427: "Новое предложение по рабочему процессу",
  str0428: "Будет сгенерировано новое предложение. Все уже введенные значения будут обнулены",
  str0429: "Количество МТР",
  str0430: "Количество поставщиков",
  str0431: "Показать историю предложений",
  str0432: "Скрыть историю предложений",
  str0433: "Новое предложение",
  str0434: "Будет создано новое предложение; нажав кнопку Подтвердить, вы будете перенаправлены на следующий экран",
  str0435: "Дата выпуска",
  str0436: "Несоответствие в поле E-mail поставщика",
  str0437: "Расходы",
  str0438: "Страховка",
  str0439: "Код группы МТР",
  str0440: "Наименование группы МТР",
  str0441: "Укажите группу МТР",
  str0442: "Укажите наименование группы МТР",
  str0443: "Другие группы МТР",
  str0444: "Проверьте группу МТР",
  str0445: "Позволяет выбирать МТР только одной группы (использовать только группу первого выбранного МТР)",
  str0446: "Вы можете выбрать только товары без группы товаров",
  str0447: "Вы можете выбрать только товары из группы товаров",
  str0448: "Карта КП",
  str0449: "Прочие суммы",
  str0450: "Тип поступления",
  str0451: "Код",
  str0452: "Код налога",
  str0453: "Создать накладную",
  str0454: "Обновить запасы",
  str0455: "Текст по умолчанию",
  str0456: "Удалить предложение",
  str0457: "Текущее предложение будет удалено, продолжить?",
  str0458: "Средние значения",
  str0459: "Средняя цена",
  str0460: "Максимально допустимый процент скидки составляет",
  str0461: "Введенная скидка больше, чем общая стоимость предложения",
  str0462: "Баланс",
  str0463: "Уже существующий поставщик",
  str0464: "Чтобы добавить участника в этот запрос предложений, введите данные ниже",
  str0465: "Если заказ на закупку оформляется на этого участника, потребуется полная регистрация",
  str0466: "Добавить поставщика",
  str0467: "Поставщик не зарегистрирован",
  str0468: "Победителем стал незарегистрированный участник. Зарегистрировать этого участника?",
  str0469: "Если не зарегистрировать, предложение не будет оформлено",
  str0470: "Сеанс истек. Войдите в систему снова.",
  str0471: "Поставщики",
  str0472: "Прочее",
  str0473: "Код города",
  str0474: "Код страны",
  str0475: "Филиал",
  str0476: "Счет",
  str0477: "Условие",
  str0478: "Тип",
  str0479: "Введенный ИНН необходимо изменить",
  str0480: "Введенный номер социального страхования необходимо изменить",
  str0481: "ИНН уже зарегистрирован",
  str0482: "Номер социального страхования уже зарегистрирован",
  str0483: "Количество ответов",
  str0484: "Создать документы",
  str0485: "Формализация",
  str0486: "Предложение с ответом",
  str0487: "Отклоненное предложение",
  str0488: "Формализация предложения",
  str0489: "Тип документа:",
  str0490: "Договор",
  str0491: '',
  str0492: "Единый договор",
  str0493: "Отдельные договоры",
  str0494: "Следующий договор",
  str0495: "Сохранить договор",
  str0496: "Сохранить договоры",
  str0497: "Добавить договор",
  str0498: "Создать единый договор для всех поставщиков, разделяя их в электронных таблицах, или создать отдельные договоры для каждого поставщика?",
  str0499: "Формализация предложения",
  str0500: "МТР",
  str0501: "Предпочтение",
  str0502: "У поставщика '{0}' нет баланса для этого МТР",
  str0503: "У товара '{0}' нет баланса",
  str0504: "При выборе поставщика с наилучшей стоимостью учитывается доступное количество и разбивка на следующие величины:",
  str0505: "Информация",
  str0506: "Расчет",
  str0507: "Указанные товары отсутствуют у поставщика нет на складе или он не продает их",
  str0508: "МТР",
  str0509: "Код поставщика или название предприятия уже использовались в этом предложении",
  str0510: "Добавить поставщика",
  str0511: "Переодические отзывы",
  str0512: "Фиксированный договор",
  str0513: "Договор",
  str0514: "Новый участник",
  str0515: "Не найдено ни одного поставщика для указанного подразделения",
  str0516: "Укажите поле Код поставщика или Название",
  str0517: "Неверный формат поля для электронной почты",
  str0518: "Невозможно добавить нового участника, потому что истек срок приема",
  str0519: "Отправить коммерческое предложение новому участнику по электронной почте?",
  str0520: "Создать",
  str0521: "Обязательные поля",
  str0522: "Предложение на рассмотрении",
  str0523: "Предложение готово",
  str0524: "Вложение сохранено успешно",
  str0525: "Ошибка при сохранении вложения",
  str0526: "Валюта обязательна",
  str0527: "Ниже будут перечислены только поставщики, представившие полные предложения, то есть отвечающие на все пункты",
  str0528: "Параметры и фильтры",
  str0529: "Укажите папку",
  str0530: "Загрузите следующие файлы и распакуйте их в папку",
  str0531: "Официальная документация по запросу",
  str0532: "Новый процесс закупок",
  str0533: "Данные сервера",
  str0534: "Укажите внешний (IP) адрес сервера",
  str0535: "Укажите порт сервера",
  str0536: "Наименование окружения, в котором выполняется рабочий процесс",
  str0537: "Наименование/IP и порт сервера процесса",
  str0538: "Многопротокольный порт",
  str0539: "Завершение работ",
  str0540: "После подтверждения данные сохраняются в базе данных, и система создает файл шаблона Appserver.ini, который вы можете скопировать в свой официальный Appserver.ini, в соответствии с правилами вашего окружения.",
  str0541: "Успешно",
  str0542: "Приведенный ниже файл Appserver.ini - это только предложение с введенными данными. При необходимости адаптируйте .ini для реального окружения",
  str0543: "Помните: загруженные .html-файлы следует поместить в место, указанное в поле рабочей директории рабочего процесса",
  str0544: "Параметры электронной почты - Закупщики",
  str0545: "Укажите адрес SMTP-сервера:",
  str0546: "Укажите порт SMTP-сервера:",
  str0547: "Укажите адрес сервера POP 3 / IMAP:",
  str0548: "Укажите порт сервера POP 3 / IMAP:",
  str0549: "Скопировать текст",
  str0550: "Шаблон AppServer.ini",
  str0551: "Конфигурация - электронная почта закупщика",
  str0552: "Протокол безопасности, используемый на сервере",
  str0553: "Введите имя/IP используемого SMTP-сервера электронной почты. Если данные не заполнены, или вы не обладаете необходимой информацией, обратитесь в отдел технической поддержки компании",
  str0554: "Введите порт используемого SMTP-сервера электронной почты. Если данные не заполнены, или вы не обладаете необходимой информацией, обратитесь в отдел технической поддержки компании",
  str0555: "Введите имя/IP используемого POP3/IMAP-сервера электронной почты. Если данные не заполнены, или вы не обладаете необходимой информацией, обратитесь в отдел технической поддержки компании",
  str0556: "Введите порт POP3/IMAP-сервера используемой электронной почты. Если данные не заполнены, или вы не обладаете необходимой информацией, обратитесь в отдел технической поддержки компании",
  str0557: "Тип протокола аутентификации сервера электронной почты: SSL, TLS или Отсутствует",
  str0558: "Создан ящик электронной почты",
  str0559: "Учетная запись электронной почты закупщика",
  str0560: "Электронная почта закупщика",
  str0561: "Имя закупщика, которое будет добавлено в электронную почту",
  str0562: 'Электронная почта закупщика, которая будет добавлена в письмо - поле "От"',
  str0563: "Аутентификация",
  str0564: "Аутентификация пользователя (учетная запись электронной почты)",
  str0565: "Пароль аутентификации (пароль электронной почты)",
  str0566: "Серверы SMTP и POP 3/IMAP",
  str0567: "Адрес SMTP-сервера",
  str0568: "Порт SMTP-сервера",
  str0569: "Адрес сервера POP 3/IMAP",
  str0570: "Порт сервера POP 3/IMAP",
  str0571: "Если продолжить, будет создан новый почтовый ящик с введенной информацией",
  str0572: "Многопротокольный порт (MultiProtocolPort) существует в INI и активен",
  str0573: "Многопротокольный порт (MultiProtocolPort) не существует в INI или неактивен (значение 0)",
  str0574: "При создании почтового ящика произошла ошибка:",
  str0575: "Укажите каталог, в котором хранятся файлы, созданные в рабочем процессе. Например, если путь лежит непосредственно в папку Рабочий процесс, введите только \рабочий процесс.",
  str0576: "Если для рабочего процесса существует виртуальное обозначение или подкаталог, например папка ABC в папке Рабочий процесс, введите \рабочий процесс\abc.",
  str0577: "Заявка",
  str0578: "Связанные заявки на закупку",
  str0579: "Настройки",
  str0580: "Налоги солидарности используются в качестве критериев для определения лучшей цены",
  str0581: "Итого без учета налогов",
  str0582: "Итого с учетом налогов",
  str0583: "Лучшая цена без учета налогов",
  str0584: "Лучшая цена с учетом налогов",
  str0585: "Созданные заказы на закупку",
  str0586: "Созданные договоры",
  str0587: "Детали ценового предложения",
  str0588: "Номер заказа",
  str0589: "Номер договора",
  str0590: "Тип договора",
  str0591: "Значение",
  str0592: "Дата начала",
  str0593: "Срок действия",
  str0594: "Показать поставщиков, связанных с МТР",
  str0595: "Скрыть поставщиков, связанных с МТР",
  str0596: "Выбор поставщиков x МТР",
  str0597: "Выбор поставщиков",
  str0598: "ИНН",
  str0599: "Вы также можете найти вышеуказанные файлы в сеансе документооборота официального документа",
  str0600: "Нажмите здесь",
  str0601: "Загрузка файлов",
  str0602: "Примечание к заявке",
  str0603: "О мастере",
  str0604: "Этот мастер призван облегчить создание рабочего процесса, используемого на NPF для отправки предложений по электронной почте",
  str0605: "На каждом этапе работы мастера введите требуемую информацию, чтобы по окончании работы она была сохранена в системе, и на экране появился файл",
  str0606: "Пример AppServer.ini для сравнения и внесения необходимых корректировок в файл AppServer.ini окружения, в котором будет развернут рабочий процесс",
  str0607: "Кроме того, по умолчанию можно добавить направления к SMTP и POP3/IMAP-серверам, используемым в организации, чтобы каждый покупатель, использующий NPF, мог настроить свою электронную почту автономно,",
  str0608: "с помощью мастера настройки электронной почты покупателя",
  str0609: "Данные сохранены, пример AppServer.ini создан",
  str0610: "Изменился почтовый ящик",
  str0611: "Были обнаружены следующие проблемы:",
  str0612: "Этот мастер призван облегчить создание почтового ящика покупателя, используемого в рабочем процессе NPF для отправки предложений по электронной почте, если активен параметр MV_PGCWF",
  str0613: "Введите информацию, запрашиваемую на каждом этапе работы мастера. Если у вас возникли вопросы на этапе указания адресов серверов SMTP и POP3/IMAP, обратитесь в отдел технической поддержки компании",
  str0614: "В конце, если информация верна, система создаст электронный почтовый ящик покупателя в рабочем процессе, инициируя предложения через этот почтовый ящик",
  str0615: "Примечания, добавленные к заявке на закупку. Эта информация не будет отправлена поставщикам",
  str0616: "Введите примечания, которые будут отправлены поставщикам (необязательно)",
  str0617: "Сохранить тмц из текущего предложения?",
  str0618: "Код закупщика",
  str0619: "Имя закупщика",
  str0620: "Поиск по закупщику",
  str0621: "Количество, которое необходимо приобрести:",
  str0622: "Введенное значение",
  str0623: "превышает имеющийся остаток",
  str0624: 'Поле "Количество" будет заполнено доступным остатком МТР',
  str0625: "Количество должно быть больше нуля",
  str0626: "Заказ на закупку и/или Договор, связанные с предложением, удалены из системы",
  str0627: "Частично выполнено",
  str0628: "От А до Я",
  str0629: "От Я до А",
  str0630: "Количество у поставщика",
  str0631: "Доступное количество у поставщика",
  str0632: "Следующий шаг",
  str0633: "Предыдущий шаг",
  str0634: "Error getting payment terms:",
  str0635: "Select payment terms?",
  str0636: "If you prefer not to select payment terms, all terms enabled for submission in WF will be automatically submitted.",
  str0637: "Centralized Purchase",
  str0638: "Centralizer Branch",
  str0639: "Branch and EIN",
  str0640: "Items from Branches to be centralized",
  str0641: "Items to be centralized",
  str0642: "PC Note",
  str0643: "This table contains all selected items to be migrated to the centralizer branch; that is, the branch logged into.",
  str0644: "Here, all purchase requests are displayed, regardless of branch. That is, all branches of the system are displayed.",
  str0645: "Select the Centralizer Branch",
  str0646: '',
  str0647: '',
  str0648: '',
  str0649: '',
  str0650: '',
  str0651: '',
  str0652: '',
  str0653: '',
  str0654: '',
  str0655: '',
  str0656: '',
  str0657: '',
  str0658: '',
  str0659: '',
  str0660: '',
  str0661: '',
  str0662: '',
  str0663: '',
  str0664: '',
  str0665: '',
  str0666: '',
  str0667: '',
  str0668: '',
  str0669: '',
  str0670: '',
  str0671: '',
  str0672: '',
  str0673: '',
  str0674: '',
  str0675: '',
  str0676: '',
  str0677: '',
  str0678: '',
  str0679: '',
  str0680: '',
  str0681: '',
  str0682: '',
  str0683: '',
  str0684: '',
  str0685: '',
  str0686: '',
  str0687: '',
  str0688: '',
  str0689: '',
  str0690: '',
  str0691: '',
  str0692: '',
  str0693: '',
  str0694: '',
  str0695: '',
  str0696: '',
  str0697: '',
  str0698: '',
  str0699: '',
  str0700: '',
  str0701: '',
  str0702: '',
  str0703: '',
  str0704: '',
  str0705: '',
  str0706: '',
  str0707: '',
  str0708: '',
  str0709: '',
  str0710: '',
  str0711: '',
  str0712: '',
  str0713: '',
  str0714: '',
  str0715: '',
  str0716: '',
  str0717: '',
  str0718: '',
  str0719: '',
  str0720: '',
  str0721: '',
  str0722: '',
  str0723: '',
  str0724: '',
  str0725: '',
  str0726: '',
  str0727: '',
  str0728: '',
  str0729: '',
  str0730: '',
  str0731: '',
  str0732: '',
  str0733: '',
  str0734: ''
};
