/* tslint:disable */
// TOTVS TypeScript translaton resource
// Reference date : 20250227

export const literalsEnUS = {
  str0001: "Módulo de Compras",
  str0002: "Purchase need",
  str0003: "Start",
  str0004: "Quotes",
  str0005: "Purchase order",
  str0006: "Purchases history",
  str0007: "Indicators",
  str0008: "Pending",
  str0009: "Partially met",
  str0010: "Met",
  str0011: "Select period",
  str0012: "Search by product code",
  str0013: "Advanced Search",
  str0014: "Exit",
  str0015: "Request",
  str0016: "Request item",
  str0017: "Product Code",
  str0018: "Product description",
  str0019: "Necessity date",
  str0020: "Unit of measurement",
  str0021: "Quantity",
  str0022: "Notes",
  str0023: "Attachments",
  str0024: "Requester",
  str0025: "Requester branch",
  str0026: "Delivery branch",
  str0027: "Cost center",
  str0028: "Ledger Account",
  str0029: "Accounting item",
  str0030: "Value class",
  str0031: "Group requests",
  str0032: "Start quotation",
  str0033: "Enter the request code",
  str0034: "Add requester",
  str0035: "Add product description",
  str0036: "Add requester branch",
  str0037: "Add delivery branch",
  str0038: "Add cost center",
  str0039: "Add ledger account",
  str0040: "Add accounting item",
  str0041: "Add value class",
  str0042: "Clear filters",
  str0043: "Apply filters",
  str0044: "Quotes",
  str0045: "Exit application?",
  str0046: "List of attachments",
  str0047: "Name",
  str0048: "Description",
  str0049: "Attachment",
  str0050: "Click to display the list of attachments",
  str0051: "Enter your user",
  str0052: "Enter your password",
  str0053: "Enter the desired alias",
  str0054: "My routines",
  str0055: "Get to know the routines",
  str0056: "Purchase order",
  str0057: "Purchase history",
  str0058: "Indicators",
  str0059: "Purchase requests.",
  str0060: "Routine for generating quotations based on the purchase requests registered. Get information from open, partially serviced, and serviced requests.",
  str0061: "Allows the updating, deletion, and analysis of quotations considering the best proposal among suppliers according to price, payment terms, and delivery term.",
  str0062: "It allows the maintenance of Purchase Orders generated according to the company's needs, representing a document that formalizes the purchase between the company and the supplier.",
  str0063: "Purchase Management",
  str0064: "Access",
  str0065: "Quotation request",
  str0066: "Quotation data",
  str0067: "Quotation group",
  str0068: "Quotation group nickname",
  str0069: "Quotation receipt",
  str0070: "Number of providers",
  str0071: "Delivery information",
  str0072: "EIN",
  str0073: "Company name",
  str0074: "Delivery address",
  str0075: "Attach files",
  str0076: "Only XML, PDF, Otcx files",
  str0077: "Size limit per file: up to 30 MB.",
  str0078: "Products requested",
  str0079: "Order",
  str0080: "Grouped requests",
  str0081: "This information is not sent to the provider.",
  str0082: "Product Code",
  str0083: "Product description",
  str0084: "Measurement unit",
  str0085: "Total qty",
  str0086: "Notes for the provider",
  str0087: "Record selected:",
  str0088: "Records selected:",
  str0089: "Record(s)",
  str0090: "Records",
  str0091: "No products requested",
  str0092: "Close",
  str0093: "Back to requests grouping",
  str0094: "Selection of Suppliers/Participants",
  str0095: "Next step",
  str0096: "New provider",
  str0097: "Hello,",
  str0098: "Logout successful!",
  str0099: "Yes",
  str0100: "No",
  str0101: "Cancel",
  str0102: "Selected Suppliers/Participants",
  str0103: "You haven't selected any supplier yet.",
  str0104: "The last suppliers are listed according to the most recent purchase invoices found for the products in this collection.",
  str0105: "Suggestion of suppliers",
  str0106: "Last suppliers",
  str0107: "All suppliers",
  str0108: "Search by EIN, trade name, or corporate name...",
  str0109: "Showing",
  str0110: "from",
  str0111: "supplier suggestion",
  str0112: "suppliers suggestions",
  str0113: "Supplier code",
  str0114: "Store",
  str0115: "E-mail",
  str0116: "Phone number",
  str0117: "Group of products met",
  str0118: "group of products",
  str0119: "Group code",
  str0120: "Group description",
  str0121: "Products registered",
  str0122: "Without information",
  str0123: "Display details",
  str0124: "Update contacts",
  str0125: "Supplier details",
  str0126: "Filter without results",
  str0127: "Cancel this operation?",
  str0128: "Suppliers selected",
  str0129: "Change Branch",
  str0130: "SSN",
  str0131: "Load more results",
  str0132: "Enter group code",
  str0133: "Group",
  str0134: "Choose the branches group desired.",
  str0135: "Branch",
  str0136: "Enter branch desired.",
  str0137: "Replace branch",
  str0138: "These are all suppliers registered in the base.",
  str0139: "No branch selected.",
  str0140: "Number of suppliers selected larger than the maximum allowed.",
  str0141: "Generate Quotation",
  str0142: "(Required)",
  str0143: "Number of characters not valid",
  str0144: "Remove",
  str0145: "Enter group nickname of this quotation",
  str0146: "Select the maximum term for receiving quotations from providers.",
  str0147: "Invalid value",
  str0148: "Number of suppliers that will take part in the quotation.",
  str0149: "Download attachment",
  str0150: "Remove item from the list",
  str0151: "Selected items",
  str0152: "Supplier",
  str0153: "Address",
  str0154: "District",
  str0155: "Postal Code",
  str0156: "Company Name",
  str0157: "City",
  str0158: "State",
  str0159: "Confirm creation of purchase quotation?",
  str0160: "Finishing the quotation request",
  str0161: "Confirm",
  str0162: "Suppliers selected for the process:",
  str0163: "Country",
  str0164: "Invalid format",
  str0165: "Send quotation to providers by e-mail?",
  str0166: "Send attachments to the supplier?",
  str0167: "More Details",
  str0168: "Charts",
  str0169: "SC vs. PC",
  str0170: "View",
  str0171: "Purchases",
  str0172: "Delete attachment",
  str0173: "Actions",
  str0174: "Delete this attachment?",
  str0175: "Area Code",
  str0176: "Main e-mail",
  str0177: "Document type",
  str0178: "Evaluate overdue proposal?",
  str0179: "Select the document type",
  str0180: "Order",
  str0181: "Contract",
  str0182: "Select whether to evaluate the overdue proposal",
  str0183: "Submission of e-mails and attachments",
  str0184: "Cancel the submission of e-mails and attachments?",
  str0185: "Process successfully completed!",
  str0186: "An open quotation already exists for this purchase request!",
  str0187: "Drag a file or click here to select",
  str0188: "Maximum of 10MB per file",
  str0189: "Invalid password and/or username, check them and try again.",
  str0190: "In progress",
  str0191: "Failed",
  str0192: "Completed",
  str0193: "Delete attachment",
  str0194: "Delete attachment '{0}'? This action cannot be reversed.",
  str0195: "Deleting attachment...",
  str0196: "Attachment '{0}' deleted successfully!",
  str0197: "The format of file '{0}' is not accepted!",
  str0198: "The size of file '{0}' exceeds 10MB!",
  str0199: "On queue",
  str0200: "The filters are only applied to the view. All selected items will be kept.",
  str0201: "Filters applied",
  str0202: "See filters",
  str0203: "Quotations requested",
  str0204: "Quotation",
  str0205: "Alias",
  str0206: "Prazo médio de entrega",
  str0207: "Status",
  str0208: "Pending",
  str0209: "Ready to analyze",
  str0210: "In analysis",
  str0211: "Completed",
  str0212: "Deleted",
  str0213: "Edit",
  str0214: "Analyze",
  str0215: "Continue analysis",
  str0216: "Preview",
  str0217: "quotations",
  str0218: "Delete quotation",
  str0219: "Reopen analysis",
  str0220: "Search by quotation number or nickname",
  str0221: "Menu option still under development!",
  str0222: "Invalid items found",
  str0223: "Error Message",
  str0224: "Cannot start a quotation",
  str0225: "Invalid items will not be considered when generating the quotation.",
  str0226: "Continue this quotation without the items described above?",
  str0227: "The quotation process was canceled.",
  str0228: "Cancel quotation",
  str0229: "This supplier was already selected!",
  str0230: "An issue was found when starting this quotation",
  str0231: "Cannot generate this quotation",
  str0232: "Edit quotation",
  str0233: "Details",
  str0234: "Partial",
  str0235: "Delete",
  str0236: "Quotation analysis",
  str0237: "Shipping Total",
  str0238: "Items Total",
  str0239: "Amount Payable",
  str0240: "Time for Delivery",
  str0241: "Formalize Purchase",
  str0242: "Quotation Details",
  str0243: "Quotation Total",
  str0244: "Items Pending",
  str0245: "Return",
  str0246: "Discounts",
  str0247: "Taxes",
  str0248: "Freight",
  str0249: "Payment Method",
  str0250: "Payment Term",
  str0251: "Disqualify Supplier",
  str0252: "Send e-mail",
  str0253: "Note",
  str0254: "Delivery",
  str0255: "Products Requested",
  str0256: "Quantity Requested",
  str0257: "Available Quantity",
  str0258: "Unit value",
  str0259: "Total value",
  str0260: "Primary Criterion",
  str0261: "Total Value",
  str0262: "Unit Value",
  str0263: "Best Price",
  str0264: "Shortest Term",
  str0265: "Highest Score",
  str0266: "Filters",
  str0267: "Discounts",
  str0268: "Taxes",
  str0269: "Total value of quotation",
  str0270: "Discounts applied",
  str0271: "Shipping Type",
  str0272: "Payment Term",
  str0273: "CIF",
  str0274: "FOB",
  str0275: "By third parties",
  str0276: "By Sender",
  str0277: "Recipient",
  str0278: "No Shipping",
  str0279: "Payment Details",
  str0280: "Item Shipping",
  str0281: "Item Total",
  str0282: "Delivery date",
  str0283: "Delivery address",
  str0284: "Consider item",
  str0285: "Discount",
  str0286: "Save",
  str0287: "Quotations",
  str0288: "Loading",
  str0289: "De-qualify",
  str0290: "Updating quotation",
  str0291: "Completed",
  str0292: "Save attachment(s)",
  str0293: "Send E-mail(s)",
  str0294: "City",
  str0295: "Freight type",
  str0300: "Days for delivery",
  str0301: "View",
  str0302: "Qualify",
  str0303: "De-qualify",
  str0304: "Other actions",
  str0305: "Quotation data:",
  str0306: "Viewing quotation:",
  str0307: "Consider item",
  str0308: "Enter the payment condition.",
  str0309: "The number of days for delivery must be higher than zero.",
  str0310: "The available quantity entered is greater than the requested quantity.",
  str0311: "An item exists without status definition.",
  str0312: "Note text to be sent to the supplier.",
  str0313: "Add e-mail",
  str0314: "To:",
  str0315: "Complete",
  str0316: "Partial",
  str0317: "Pending",
  str0318: "Rejected",
  str0319: "Disqualified",
  str0320: "Complete (E-mail)",
  str0321: "Partial (E-mail)",
  str0322: "Disqualify this supplier?",
  str0323: "Qualify this supplier?",
  str0324: "E-mail duplicated",
  str0325: "Maximum field size is",
  str0326: "characters",
  str0327: "Maximum field size",
  str0328: "exceeded.",
  str0329: "Attachment(s)",
  str0330: "Invalid e-mail",
  str0331: "New attachment",
  str0332: "File",
  str0333: "By Full Proposal",
  str0334: "By Item",
  str0335: "Total average",
  str0336: "Display suppliers",
  str0337: "Hide suppliers",
  str0338: "Items not answered",
  str0339: "Notice",
  str0340: "Items exist that were not answered by any supplier",
  str0341: "One or more items were not answered by any supplier",
  str0342: "Remove the item(s) from the quotation to continue",
  str0343: "If you click Do not remove, only viewing by item will be available",
  str0344: "Below average",
  str0345: "Above average",
  str0346: "Unit",
  str0347: "Analyze by item",
  str0348: "Remove items",
  str0349: "If you click Yes, only the analysis by item will be available",
  str0350: "If you click Yes, all items selected ( items not served) will be removed from the current quotation.",
  str0351: "Item",
  str0352: "Estimated date",
  str0353: "Unit average",
  str0354: "Available qty.",
  str0355: "Currency",
  str0356: "Currency Rate",
  str0357: "There are no suppliers for this analysis mode. Use the option by item",
  str0358: "This action cannot be reversed.",
  str0359: "Existem itens que não foram respondidos por nenhum fornecedor.",
  str0360: "No complete proposals exist to analyze, continue analyzing per item only, or remove unquoted items?",
  str0361: "Delete quotation",
  str0362: "Are you sure you want to delete the quotation",
  str0363: "The discount entered is greater than the total value of the item.",
  str0364: "Generating documents",
  str0365: "No winning item selected.",
  str0366: "On average",
  str0367: "Confirm the analysis and generate documents with the selected winners?",
  str0368: "Formalize the quotation",
  str0369: "Order by:",
  str0370: "Days",
  str0371: "Price plus taxes",
  str0372: "Taxes",
  str0373: "IPI value",
  str0374: "ICMS value",
  str0375: "ISS value",
  str0376: "Comp. ICMS",
  str0377: "ICMS Solidario value",
  str0378: "DIFAL value",
  str0379: "IPI rate",
  str0380: "ICMS rate",
  str0381: "ISS rate",
  str0382: "Comp. rate",
  str0383: "Values by item",
  str0384: "Percentage by item",
  str0385: "Taxes by item",
  str0386: "Calculate taxes",
  str0387: "Total taxes",
  str0388: "Total values",
  str0389: "Gross value",
  str0390: "Net Value",
  str0391: "Cannot apply discount to a product without value",
  str0392: "Target difal base",
  str0393: "Source Difal",
  str0394: "Target Difal",
  str0395: "Base values",
  str0396: "IPI Base",
  str0397: "ICMS Base",
  str0398: "Total items value",
  str0399: "ICMS Solidario base",
  str0400: "Taxes calculated successfully.",
  str0401: "Total item value",
  str0402: "No quotation item was answered",
  str0403: "The quotation does not serve 100% of the items (quantity available is lower than the one requested, or zeroed unit value).",
  str0404: "The quotation serves 100% of the items.",
  str0405: "Quotation rejected by supplier.",
  str0406: "Supplier's quotation disqualified.",
  str0407: "Quotation replied by e-mail and does not serve 100% of the items (quantity available is lower than requested, or zeroed unit value).",
  str0408: "Quotation replied by e-mail, serves 100% of the items.",
  str0409: "This quotation does not have an attachment.",
  str0410: "Only the attachments entered when generating the quotation will be sent, no new files can be attached to the e-mail.",
  str0411: "PC/CT Note",
  str0412: "Item already selected!",
  str0413: "We found an issue when sending the e-mail. Check the Workflow configurations!",
  str0414: "Enter the justification for the items.",
  str0415: "The following items do not have the lowest value; thus, the choice must be justified.",
  str0416: "Important",
  str0417: "The proposal selected does not have the lowest value; thus, the choice must be justified.",
  str0418: "Enter a justification.",
  str0419: "Add note",
  str0420: "Justification",
  str0421: "All",
  str0422: "Type of status",
  str0423: "Proposal",
  str0424: "Customized fields",
  str0425: "Taxes by item",
  str0426: "Other fields",
  str0427: "New workflow proposal",
  str0428: "A new proposal will be generated. All values already entered will be set to zero",
  str0429: "Quant. products",
  str0430: "Quant. suppliers",
  str0431: "Display history of proposals",
  str0432: "Hide history of proposals",
  str0433: "New manual proposal",
  str0434: "A new proposal will be generated; by clicking confirm, you will be redirected to the next screen.",
  str0435: "Issue Date",
  str0436: "Inconsistency in field E-mail of supplier",
  str0437: "Expenses",
  str0438: "Insurance",
  str0439: "Product Group Code",
  str0440: "Product Group Description",
  str0441: "Enter product group",
  str0442: "Enter description of product group",
  str0443: "Different Product Groups",
  str0444: "Validate product group?",
  str0445: "Allow selecting only items of same product group (use only the group of first item selected).",
  str0446: "You can only select items without product group.",
  str0447: "You can only select items of product group",
  str0448: "Quotation Map",
  str0449: "Other values",
  str0450: "Type of inflow",
  str0451: "Code",
  str0452: "Tax code",
  str0453: "Generate trade note",
  str0454: "Update stock",
  str0455: "Default text",
  str0456: "Delete proposal",
  str0457: "The current proposal will be deleted, continue?",
  str0458: "Average values",
  str0459: "Uni. Average",
  str0460: "The maximum discount percentage allowed is",
  str0461: "The discount entered is greater than the total quotation value.",
  str0462: "Balance",
  str0463: "Supplier already existing",
  str0464: "To add a participant in this quotation request, enter the data below.",
  str0465: "If a purchase order is made with this participant, the full registration will be needed.",
  str0466: "Add supplier",
  str0467: "Supplier not registered",
  str0468: "The quotation had a non-registered participant as the winner. Register them now?",
  str0469: "If negative, this quotation will not be formalized",
  str0470: "Session expired. Log in again.",
  str0471: "Suppliers File",
  str0472: "Others",
  str0473: "City Code",
  str0474: "CrtCd",
  str0475: "Branch",
  str0476: "Account",
  str0477: "Condition",
  str0478: "Type",
  str0479: "The EIN entered must be changed.",
  str0480: "The SSN entered must be changed.",
  str0481: "EIN already registered",
  str0482: "SSN already registered",
  str0483: "No. of Replies",
  str0484: "Generate documents",
  str0485: "Formalization",
  str0486: "Answered Prop.",
  str0487: "Discarded Prop.",
  str0488: "Proposal Formalization",
  str0489: "Document type:",
  str0490: "Contract",
  str0491: "Pedido compra",
  str0492: "Single Contract",
  str0493: "Separate Contracts",
  str0494: "Next contract",
  str0495: "Save contract",
  str0496: "Save contracts",
  str0497: "Add Contract",
  str0498: "Generate a single contract for all suppliers, separating them in spreadsheets, separate contracts for each supplier?",
  str0499: "Proposal Formalization",
  str0500: "Product",
  str0501: "Preference",
  str0502: "The supplier '{0}' has no balance for this product.",
  str0503: "The item '{0}' has no balance.",
  str0504: "The selection of the supplier with best value considers the available quantity and the breakdown of the following values:",
  str0505: "Information",
  str0506: "Calculation",
  str0507: "The supplier does not have stock or does not sell the items quoted.",
  str0508: "Products",
  str0509: "The code of the supplier or business name were already used in this quotation.",
  str0510: "Add Supplier",
  str0511: "Occasional Measurement",
  str0512: "Fixed Contract",
  str0513: "Contact",
  str0514: "New participant",
  str0515: "No supplier found for the store entered.",
  str0516: "Enter the Supplier Code or Business Name field.",
  str0517: "Format of e-mail field invalid.",
  str0518: "Cannot add a new participant because the quotation is out of the receipt deadline.",
  str0519: "Send the quotation to the new participant by e-mail?",
  str0520: "Generate",
  str0521: "Mandatory Fields",
  str0522: "Pending quotation.",
  str0523: "Quotation finished.",
  str0524: "Attachment saved successfully.",
  str0525: "Error saving attachment.",
  str0526: "The currency is mandatory.",
  str0527: "Only suppliers with complete proposals, that is, answering all items, will be listed below.",
  str0528: "Parameter and Files",
  str0529: "Enter work directory of Workflow",
  str0530: "Download the following files and extract them to the aforementioned folder.",
  str0531: "Official documentation for query",
  str0532: "New Purchases Flow",
  str0533: "Server Data",
  str0534: "Enter the public (IP) address or the workflow server",
  str0535: "Enter the workflow server port",
  str0536: "Name of Environment in which the Workflow is executed",
  str0537: "Workflow server Name/IP and Port",
  str0538: "Multiprotocol Port",
  str0539: "Finalization",
  str0540: "Upon confirmation, the data are saved in the database and the system creates an Appserver.ini template file you can copy to your official Appserver.ini, in accordance with your environment rules.",
  str0541: "Success",
  str0542: "The Appserver.ini file below is only a suggestion with the data entered. Adapt the .ini of the actual environment as needed.",
  str0543: "Remember: place the downloaded .html files in the location entered in the work directory field of the Workflow",
  str0544: "E-mail Parameters - buyers",
  str0545: "Enter SMTP server address:",
  str0546: "Enter the SMTP server port:",
  str0547: "Enter POP 3 / IMAP server address:",
  str0548: "Enter POP 3 / IMAP server port:",
  str0549: "Copy text",
  str0550: "AppServer.ini Template",
  str0551: "Configuration - e-mail of the buyer",
  str0552: "Security protocol used in server",
  str0553: "Fill in the name/IP of SMTP server of e-mail in use. If left blank or you lack the information, query the company's technical department.",
  str0554: "Fill in the port of SMTP server of e-mail in use. If left blank or you lack the information, query the company's technical department.",
  str0555: "Fill in the name/IP of POP3/IMAP server of e-mail in use. If left blank or you lack the information, query the company's technical department.",
  str0556: "Fill in the port of POP3/IMAP server of e-mail in use. If left blank or you lack the information, query the company's technical department.",
  str0557: "Authentication protocol type of e-mail server: SSL, TLS or None",
  str0558: "E-mail inbox created.",
  str0559: "Purchaser e-mail account",
  str0560: "Purchaser e-mail",
  str0561: "Name of purchaser to be added to e-mail",
  str0562: "Purchaser e-mail to be added to e-mail - field 'From'.",
  str0563: "Authentication",
  str0564: "User authentication (e-mail account)",
  str0565: "Authentication password (e-mail password)",
  str0566: "SMTP and POP 3/IMAP Servers",
  str0567: "SMTP server address",
  str0568: "SMTP server port",
  str0569: "POP 3/IMAP server address",
  str0570: "POP 3/IMAP server port",
  str0571: "When continuing, a new mailbox is created with the entered information.",
  str0572: "Multiprotocol port (MultiProtocolPort) exists in INI and is active.",
  str0573: "Multiprotocol Port (MultiProtocolPort) does not exist in INI or is inactive (Value 0).",
  str0574: "An error occurred when creating mailbox:",
  str0575: "Enter the directory in which the files created by workflow are stored. For example, if the path goes directly into the Workflow folder, enter only \\workflow.",
  str0576: "If there is a virtual alias or subdirectory for the workflow, as the ABC folder within the Workflow folder, enter \\workflow\\abc.",
  str0577: "PR Code",
  str0578: "Related purchase requests",
  str0579: "Configuration Workflow",
  str0580: "Solidary ICMS and IPI taxes are used as criteria for best price.",
  str0581: "Total without taxes",
  str0582: "Total with taxes",
  str0583: "Best price without taxes",
  str0584: "Best price with taxes",
  str0585: "Generated purchase orders",
  str0586: "Generated Contracts",
  str0587: "Price Quote Details",
  str0588: "PO Number",
  str0589: "Contract Number",
  str0590: "Contract Type",
  str0591: "Value",
  str0592: "Start Date",
  str0593: "Validity",
  str0594: "Show providers linked to the product",
  str0595: "Hide providers linked to the product",
  str0596: "Selection of Supliers x Product",
  str0597: "Selection of Suppliers",
  str0598: "EIN/SSN",
  str0599: "You may also find the above files in the official document's Workflow session.",
  str0600: "Click here",
  str0601: "File downloads",
  str0602: "Request Note",
  str0603: "About the Wizard",
  str0604: "This wizard aims to ease the creation of the workflow used on the NFC to send proposals by e-mail.",
  str0605: "In each stage of the wizard, enter the information requested so that, at the end of the wizard, they are saved into the system, and a file is displayed ",
  str0606: "Example AppServer.ini for comparison and making the adjustments needed in the AppServer.ini file of the environment in which the workflow will be deployed.",
  str0607: "Plus, directions to the SMTP and POP3/IMAP servers used in the organization can be added as default so that each buyer that uses NFC can configure their e-mail autonomously,",
  str0608: "using the e-mail configuration wizard of the buyer.",
  str0609: "Data saved, and AppServer.ini example generated.",
  str0610: "E-mail inbox changed.",
  str0611: "The following issues were found:",
  str0612: "This wizard aims to ease the creation of the e-mail inbox of the buyer used in the NFC Workflow to send proposals by e-mail if the parameter MV_PGCWF is active.",
  str0613: "Enter the information requested in each stage of the wizard. If you have questions in the SMTP and POP3/IMAP servers address stage, contact the technical department of your organization.",
  str0614: "At the end, if the information are correct, the system will create the e-mail inbox of the buyer in the workflow, triggering the proposals through this inbox.",
  str0615: "Notes added to the purchase request. This information will not be sent to suppliers.",
  str0616: "Enter notes to be sent to the suppliers (optional).",
  str0617: "Keep values from the current proposal?",
  str0618: "Buyer code",
  str0619: "Name of the buyer",
  str0620: "Search by buyer",
  str0621: "Quantity to be acquired:",
  str0622: "The value entered ",
  str0623: "is greater than the balance available ",
  str0624: "The Quantity field will be filled in with the available balance of the item.",
  str0625: "The quantity must be greater than zero.",
  str0626: "Purchase Order and/or Contract related to the quotation removed from the system.",
  str0627: "Partially met",
  str0628: "From A - Z",
  str0629: "From Z - A",
  str0630: "Quantity at the Supplier",
  str0631: "Available qty. Supplier",
  str0632: "Next stage",
  str0633: "Previous stage",
  str0634: "Error getting payment terms:",
  str0635: "Select payment terms?",
  str0636: "If you prefer not to select payment terms, all terms enabled for submission in WF will be automatically submitted.",
  str0637: "Centralized Purchase",
  str0638: "Centralizer Branch",
  str0639: "Branch and EIN",
  str0640: "Items from Branches to be centralized",
  str0641: "Items to be centralized",
  str0642: "PC Note",
  str0643: "This table contains all selected items to be migrated to the centralizer branch; that is, the branch logged into.",
  str0644: "Here, all purchase requests are displayed, regardless of branch. That is, all branches of the system are displayed.",
  str0645: "Select the Centralizer Branch",
  str0646: "Centralizar",
  str0647: "Tentar novamente",
  str0648: "Nome da Caixa de e-mail:",
  str0649: "Informe o nome da caixa de e-mail. Utilize apenas letras, números e sublinhado.",
  str0650: "Por padrão, o nome será o e-mail do usuário - constando somente letras, números e sublinhado (_) - ocultando demais caracteres.",
  str0651: "O nome da caixa de e-mail é gravado apenas na inclusão. Se já existir, qualquer alteração nesse campo é ignorada.",
  str0652: "Tem certeza que deseja remover o item: ",
  str0653: "Filtro do comprador",
  str0654: "Itens da cotação",
  str0655: "Expandir Visualização - Produtos Solicitados",
  str0656: "Substituição do aprovador",
  str0657: "Aprovador",
  str0658: "Substituto",
  str0659: "Substituir e transferir permanente",
  str0660: "Selecione o aprovador:",
  str0661: "Transferência temporária",
  str0662: "Período de ausência",
  str0663: "Todos os documentos e grupos de aprovação serão transferidos para o aprovador substituto permanentemente.",
  str0664: "imagem tela home",
  str0665: "Imagem transferência de documentos",
  str0666: "Num. Documentos",
  str0667: "Busque por número do documento",
  str0668: "Selecione o aprovador substituto:",
  str0669: "Autorização de entrega",
  str0670: "Contato Parceria",
  str0671: "Documento Garantia",
  str0672: "Medição",
  str0673: "Revisão",
  str0674: "Documento de Entrada",
  str0675: "Solicitação ao Armazém ",
  str0676: "Solicitação de Transferência",
  str0677: "Documento Agro",
  str0678: "Transferência de documentos",
  str0679: "Deseja transferir os documentos selecionados para o aprovador substituto?",
  str0680: "Documentos",
  str0681: "Transfere os documentos direcionados ao aprovador para o substituto por determinado tempo de ausência, que deve ser previamente estabelecido.",
  str0682: "Transfere todos os documentos do aprovador, que estão aguardando a liberação, incluindo os grupos de aprovação para o aprovador substituto.",
  str0683: "Migrar cotações",
  str0684: "O objetivo desta rotina é realizar a migração de cotações geradas pela rotina de 'Gera cotações, Atualiza cotação e Analisa cotação' para o ",
  str0685: "Novo Fluxo de Compras.",
  str0686: "Deve-se preencher a data inicial e final de emissão para filtrar as cotações a serem migradas.",
  str0687: "Atenção:",
  str0688: "Esta ação não pode ser revertida. O processamento será realizado em segundo plano.",
  str0689: "Data inicial",
  str0690: "Data final",
  str0691: "Receber notificação ao término do processamento?",
  str0692: "Sim",
  str0693: "Não",
  str0694: "Necessário possuir e-mail pré-configurado no ambiente.",
  str0695: "Deseja transferir permanentemente todos os documentos do aprovador para o aprovador substituto?",
  str0696: "Após a confirmação os documentos transferidos poderão ser consultados na lista do aprovador substituto.",
  str0697: "Nenhum",
  str0698: "Esta caixa de e-mail já foi cadastrada.",
  str0699: "Envio de e-mails e anexos",
  str0700: "Receber notificação da resposta?",
  str0701: "Notifica resumo para o fornecedor?",
  str0702: "Quando o fornecedor responder à proposta, você receberá um e-mail informando que a resposta foi enviada.",
  str0703: "Ao responder à proposta, o fornecedor receberá um e-mail contendo um resumo dos itens por ele respondidos.",
  str0704: "A Justicativa deve conter apenas ",
  str0705: " caracteres.",
  str0706: "Transferência pontual",
  str0707: "Transfere os documentos selecionados de forma pontual para o aprovador substituto.",
  str0708: "Compradores",
  str0709: "Defina os tipos de documentos para o grupo de aprovação:",
  str0710: "Grupo",
  str0711: "Aprovadores",
  str0712: "Fluxo de Aprovação",
  str0713: "Teste de Fluxo",
  str0714: "Concluído com sucesso",
  str0715: "Entidades contábeis",
  str0716: "O item",
  str0717: "não foi preenchido.",
  str0718: "Os itens",
  str0719: "não foram preenchidos.",
  str0720: "Não é permitido itens com informações repetidas. Verifique os itens",
  str0721: "Preencha os campos Grupo e Descrição.",
  str0722: "e",
  str0723: "Defina o grupo de aprovação:",
  str0724: "Este grupo irá utilizar entidades contábeis?",
  str0725: "Habilita entidades contábeis",
  str0726: "Defina as entidades contábeis para o grupo de aprovação:",
  str0727: "Adicionar",
  str0728: "Grupos de aprovação",
  str0729: "Busque pelo nome ou código do grupo",
  str0730: "Num. Pedido de Compra",
  str0731: "Existem itens que necessitam de intervenção manual, pois não foi possível selecionar automaticamente o melhor valor, pois algum dos fornecedores estão oferecendo quantidade menor que a solicitada.",
  str0732: "Os itens que necessitam de atenção estão listados abaixo:",
  str0733: "Continuar",
  str0734: "Ao clicar em continuar, será aberta a tela de configuração de preferências do contrato para finalizar a inclusão do mesmo.",
  str0735: "Wizard - Grupo de Aprovação",
  str0736: "Este passo do wizard tem como objetivo preencher as informações do grupo de aprovação. Nele, é possível definir o código, a descrição, os tipos de documentos e as entidades contábeis.",
  str0737: "Remover itens selecionados",
  str0738: "É necessário adicionar ao menos um item na tabela de entidades contábeis.",
  str0739: "Defina os aprovadores para o grupo de aprovação:",
  str0740: "Aprovadores cadastrados",
  str0741: "Aprovadores do grupo de aprovação",
  str0742: "É necessário adicionar ao menos um aprovador para prosseguir.",
  str0743: "Não é possível selecionar um aprovador que já foi adicionado.",
  str0744: "O código do superior não pode ser igual ao do aprovador.",
  str0745: "Preencha todos os campos obrigatórios.",
  str0746: "Defina o fluxo de aprovação para o grupo:",
  str0747: "Defina o tipo de compras vinculado ao aprovador:",
  str0748: "Este tipo de compra já foi inserido.",
  str0749: "Preencha o campo",
  str0750: "Preencha o item que está em branco antes de adicionar uma nova linha.",
  str0751: "Defina os tipos de documentos para o teste de fluxo: ",
  str0752: "Aprovação por documento",
  str0753: "Aprovação por item",
  str0754: "Validar",
  str0755: "Valor documento",
  str0756: "Clique em detalhes para visualizar o teste de fluxo: ",
  str0757: "Não foi gerado teste de fluxo para este tipo de documento.",
  str0758: "Selecione ao menos um tipo de documento para gerar o teste de fluxo.",
  str0759: "Novo Grupo",
  str0760: "Finalizar",
  str0761: "Bloqueado (aguardando outros níveis)",
  str0762: "Aguardando liberação do usuário",
  str0763: "Liberado pelo usuário",
  str0764: "Deseja gravar o grupo de aprovação?",
  str0765: "A configuração do grupo de aprovação foi concluída com sucessso.",
  str0766: "Solicitação de Compra",
  str0767: "Esta modalidade não esta habilitada para propostas geradas pelo Portal do Fornecedor",
  str0768: "Observação do fornecedor",
  str0769: "Observação fornecedor",
  str0770: "Responder",
  str0771: "Relação com o Fornecedor",
  str0772: "Agradecemos o interesse nesta funcionalidade!",
  str0773: "Neste momento será muito importante entender como funciona a gestão de relacionamento com fornecedores (SRM) na sua empresa. ",
  str0774: "Preparamos uma breve pesquisa com 5 questões e a sua ajuda será fundamental para entendermos o valor do SRM para a sua operação.",
  str0775: "Poderia nos ajudar? Não vai levar 5 minutos e pode deixar que as respostas recebidas serão apenas para uso interno.",
  str0776: "Agradecemos por participar do processo de evolução do módulo de Compras!",
  str0777: "Clique em ",
  str0778: "para acessar a pesquisa.",
  str0779: "A sequência de entidades contábeis informada no produto",
  str0780: "não existe no grupo de aprovação.",
  str0781: "Grupo de produtos",
  str0782: "UM",
  str0783: "Armazém",
  str0784: "Num. Cotação",
  str0785: "Valor Item",
  str0786: "Cód. Solicitação",
  str0787: "Busque pelo código de produto",
  str0788: "Qtd. Disponível",
  str0789: "Qtd. Entrada Prevista",
  str0790: "Últ. Preço de Compra",
  str0791: "Últ. Data de Compra",
  str0792: "Maior Qtd. Comprada",
  str0793: "Prazo médio de Entrega",
  str0794: "PCs em aberto",
  str0795: "PCs entregues",
  str0796: "Busque pelo código da Solicitação",
  str0797: "Busque pelo código da Cotação",
  str0798: "Busque pelo código do Fornecedor",
  str0799: "Solicitações de Compras",
  str0800: "Receber e-mail quando o(s) fornecedor(es) responder(em) à proposta?"
};
