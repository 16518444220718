import { PurchaseRequestsModule } from './features/purchase-requests/purchase-requests.module';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PoI18nModule, PoI18nConfig, PoModule, PoDialogModule, PoGridModule, PoFieldModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { MingleService } from '@totvs/mingle';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './core/home/home.module';
import { AppInitService } from './shared/services/appinit.service';

import { literalsPtBR } from './i18n/pgc_front-PtBR';
import { literalsEnUS } from './i18n/pgc_front-EnUS';
import { literalsEsES } from './i18n/pgc_front-EsES';
import { literalsRuRU } from './i18n/pgc_front-RuRU';

import localeBr from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import {registerLocaleData}  from '@angular/common';

import { CommonModule } from '@angular/common';
import { ProSessionInfoService, ProtheusLibCoreModule } from '@totvs/protheus-lib-core';
import { HttpInterceptorReq } from './shared/interceptors/http.interceptor';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';

registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeRu);
registerLocaleData(localeBr);

const proSessionInfoService = new ProSessionInfoService();
const localePO = proSessionInfoService.getIdiom() ?? 'pt-BR';
const i18nConfig: PoI18nConfig = {
  default: {
    language: navigator.language,
    context: 'general',
    cache: true
  },
  contexts: {
    general: {
      'pt-BR': literalsPtBR,
      'en-US': literalsEnUS,
      'es-ES': literalsEsES,
      'ru': literalsRuRU
    }
  },
};

export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.mingleConfiguration();
  }
}

@NgModule({ declarations: [
        AppComponent
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        PoModule,
        PoDialogModule,
        PoGridModule,
        PoFieldModule,
        PoI18nModule.config(i18nConfig),
        PoTemplatesModule,
        HomeModule,
        PurchaseRequestsModule,
        ProtheusLibCoreModule], providers: [
        AppInitService,
        MingleService,
        { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorReq, multi: true },
        { provide: LOCALE_ID, useValue: localePO },
        provideHttpClient(withInterceptorsFromDi()),
        BrowserDynamicTestingModule
    ] })

export class AppModule { }