<po-page-login
  p-logo="../../../../assets/icons/totvs_pb.png"
  p-product-name= "{{literals.str0063}}"
  p-hide-remember-user="true"
  [p-literals]="customLiterals"
  [p-custom-field]="customField"
  [p-loading]="isLoading"
  [p-login-errors]="loginErrors"
  [p-password-errors]="loginErrors"
  (p-language-change)="handleChangeLanguage($event)"
  (p-login-submit)="login($event)"
  (p-password-change)="passwordChange()">
</po-page-login>
